import React, { Suspense } from 'react'
import ErrorBoundary from './errorboundary'
import Spinner from 'components/common/spinner'

const DashboardPage = React.lazy(() => import('./dashboard'))

const DashboardViewContainer = (props) => (
    <div>
        <Suspense fallback={<Spinner />}>
            <ErrorBoundary>
                <DashboardPage {...props} />
            </ErrorBoundary>
        </Suspense>
    </div>
)

export default DashboardViewContainer
