import React, { useState } from 'react'
import { Modal } from 'antd'
import PropTypes from 'prop-types'

import CancelIcon from 'assets/cancel.svg'
import { screenIs } from './ScreenSize'

const ModalContainer = (props) => {
    return (
        <>
            <Modal
                className={screenIs("md") ? 'incident-modal-root' : 'mobile-modal-root'}
                bodyStyle={props.bodyStyle || { background: '#20293C' }}
                visible={props.visible}
                width={props.width}
                onCancel={props.closeModal}
                closable={props.closable}
                maskClosable = {props.maskClosable ? props.maskClosable:false}
                closeIcon={
                    <img
                        className="mx-auto mt-2 focus:outline-none"
                        src={CancelIcon}
                        width="25"
                        height="25"
                        alt="close"
                    />
                }
                footer={props.footer}
            >
                {props.children}
            </Modal>
        </>
    )
}
ModalContainer.defaultProps = {
    visible: false,
    width: '40%',
    footer: null,
    children: '',
    closable: true,
    bodyStyle: null,
}

ModalContainer.propTypes = {
    closeModal: PropTypes.any.isRequired,
}

export default ModalContainer
