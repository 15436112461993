import React, { useState, useEffect } from 'react'
import { Switch, Route, BrowserRouter, useHistory } from 'react-router-dom'
import { createBrowserHistory } from 'history'

import Navigator from 'components/common/Navigator'
import Header from 'components/common/Navigator/header/header'
import {screenIs} from '../src/components/common/ScreenSize';

import { useMediaQueryUtils } from 'constants/exportHooks'

import './App.css'
import './components/common/common.css'
import 'antd/dist/antd.dark.less'
import AppRouter from 'AppRouter'
import { useAuth0 } from '@auth0/auth0-react'
import Spinner from 'components/common/spinner'

import {
    ApolloClient,
    ApolloProvider,
    InMemoryCache,
    HttpLink,
    split,
} from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'
import { WebSocketLink } from '@apollo/client/link/ws'

function getApolloClient() {
    const accessToken = localStorage.getItem('access_token')
    const httpLink = new HttpLink({
        uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
        headers: {
            authorization: `Bearer ${accessToken}`,
            'x-hasura-access-key': `${process.env.REACT_APP_HASURA_SECRET_KEY}`,
            'content-type': 'application/json',
        },
    })

    const wsLink = new WebSocketLink({
        uri: process.env.REACT_APP_WS_GRAPHQL_ENDPOINT,
        options: {
            reconnect: true,
            connectionParams: {
                headers: {
                    authorization: `Bearer ${accessToken}`,
                    'x-hasura-access-key': `${process.env.REACT_APP_HASURA_SECRET_KEY}`,
                    'content-type': 'application/json',
                },
            },
        },
    })

    // The split function takes three parameters:
    //
    // * A function that's called for each operation to execute
    // * The Link to use for an operation if the function returns a "truthy" value
    // * The Link to use for an operation if the function returns a "falsy" value
    const splitLink = split(
        ({ query }) => {
            const definition = getMainDefinition(query)
            return (
                definition.kind === 'OperationDefinition' &&
                definition.operation === 'subscription'
            )
        },
        wsLink,
        httpLink
    )

    const client = new ApolloClient({
        link: splitLink,
        cache: new InMemoryCache(),
    })
    return client
}

function App() {
    // const client = createApolloClient()
    const [userAuthenticated, setUserAuthenticated] = useState(false)
    // console.log('isAuthenticated', isAuthenticated)
    const { isDesktop, isMobile, isTablet } = useMediaQueryUtils()
    const [sidebarCollapsed, setSidebarCollapsed] = useState(false)
    // const { loginWithRedirect, user, isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0()
    const history = useHistory()

    // useEffect(() => {
    //     if (!isLoading && !isAuthenticated) {
    //         loginWithRedirect()
    //     }
    //     const getUserMetadata = async () => {
    //         const auth0audience = process.env.REACT_APP_AUTH0_AUDIENCE

    //         try {
    //             const accessToken = await getAccessTokenSilently({
    //                 audience: auth0audience,
    //                 scope: 'read:current_user',
    //             })
    //             // console.log('accessToken', accessToken)
    //             // localStorage.setItem('access_token', accessToken)

    //             setUserAuthenticated(true)
    //         } catch (e) {
    //             console.log('error in getting access token', e.message)
    //         }
    //     }
    //     getUserMetadata()
    // }, [isLoading, getAccessTokenSilently])

    const toggleSidebar = () => {
        setSidebarCollapsed(!sidebarCollapsed)
    }

    // if (isLoading) {
    //     return <Spinner />
    // }

    const apolloClient = getApolloClient()

    return (
        <>
            {/* {isAuthenticated ? ( */}
                <BrowserRouter>
                    <ApolloProvider client={apolloClient}>
                        <div className="h-full">
                            <div className="w-full h-full">
                                {/* <Navbar /> */}
                                <Header
                                    client={apolloClient}
                                    toggleSidebar={toggleSidebar}
                                    sidebarCollapsed={sidebarCollapsed}
                                    setSidebarCollapsed={setSidebarCollapsed}
                                />
                                <div className="w-full h-full flex flex-wrap">
                                    <Navigator
                                        sidebarCollapsed={sidebarCollapsed}
                                        toggleSidebar={toggleSidebar}
                                        // userDetails={userDetails?.user[0]}
                                    />
                                    <div
                                        className={
                                            sidebarCollapsed === true 
                                                ? `${screenIs("md") ? `w-95-percent mt-10 pl-3 md:pl-10` : `w-full mt-10 pl-3`}`
                                                : 'w-full md:w-4/5 mt-8 2xl:mt-10 pl-5 md:pl-20'
                                        }
                                    >
                                        <AppRouter />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ApolloProvider>
                </BrowserRouter>
            {/* ) : null} */}
        </>
    )
}

export default App
