import React, { useState, useRef, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'

import MenuPopup from 'components/common/menuPopup'
import DropdownArrow from 'assets/dropdown_arrow.png'
import UserAvatar from 'assets/user-avatar-32.png'
import ProfilePlaceholder from 'assets/profile_placeholder.png'

const MenuContent = ({ logout, userDetails }) => {
    const history = useHistory()
    return (
        <>
            <div className="">
                <p
                    className="px-2 py-1 hover:bg-primary-red-500 text-white cursor-pointer "
                    // onClick={() => {
                    //     history.push(`/team/edit-member/${userDetails.id}`)
                    // }}
                >
                    My Profile
                </p>
                <p
                    className="px-2 py-1 hover:bg-primary-red-500 text-white cursor-pointer"
                    onClick={() => {
                        // logout({ returnTo: window.location.origin })
                    }}
                >
                    Sign out
                </p>
            </div>
        </>
    )
}

function UserMenu(props) {
    // const { logout, user, isAuthenticated } = useAuth0()
    const [dropdownOpen, setDropdownOpen] = useState(false)
    return (
        <div className="relative inline-flex">
            {/* {isAuthenticated ? ( */}
                <MenuPopup
                    menuContent={
                        <MenuContent
                            // logout={logout}
                            // userDetails={props.userDetails}
                        />
                    }
                >
                    <div className="flex items-center truncate px-2 cursor-pointer">
                        {/* <div className=" text-neutral-gray-50 text-base">
                            {user.name}
                        </div> */}
                        <img
                            className="rounded-50-percent z-1 
                            w-8 h-8
                            2xl:w-10 2xl:h-10"
                            // src={user.picture ? user.picture : ProfilePlaceholder}
                        />
                        <img className="pl-2" src={DropdownArrow} />
                    </div>
                </MenuPopup>
            {/* ) : null} */}
        </div>
    )
}

export default UserMenu
