import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import routes from '../../../routes'
import OnMountTransition from 'components/common/OnMountTransition'

import DropdownArrow from 'assets/dropdown_arrow.png'

import HomeIcon from 'assets/home_selected.svg'

import TeamIconGrey from 'assets/team.svg'
import TeamIconRed from 'assets/team_selected.svg'

import IncidentIconGrey from 'assets/incidents.svg'
import IncidentIconRed from 'assets/incidents_selected.svg'

import AssetsIconGrey from 'assets/assets.svg'
import AssetsIconRed from 'assets/assets_selected.svg'

import TemplateIconGrey from 'assets/templates.svg'
import TemplateIconRed from 'assets/templates_selected.svg'

import ReportIconGrey from 'assets/reports.svg'
import ReportIconRed from 'assets/reports_selected.svg'

import SettingsIconGrey from 'assets/settings.svg'
import SettingsIconRed from 'assets/settings_selected.svg'

import ManufacturingIcon from 'assets/manufacturing-icon.png'

import TransitionIcon from 'assets/transition-icon.png'

import Distribution from 'assets/moving-truck.png'

import Restaurant from 'assets/restaurant.png'

import LayerIcon from 'assets/layer-icon.png'

import OrgLogo from 'assets/placeholder.png'
import { screenIs } from '../ScreenSize'

const Section = ({
    routeName,
    secondaryRouteName,
    imageGrey,
    imageRed,
    sectionLabel,
    currentRoute,
    setCurrentRoute,
}) => {
    const history = useHistory()
    return (
        <React.Fragment>
            <div
                className="py-4 w-full flex items-center justify-center  "
                onClick={(e) => {
                    e.stopPropagation()
                    history.push(routes[routeName])
                    setCurrentRoute(routes[routeName])
                }}
            >
                <div
                    className={
                        currentRoute === routes[routeName] ||
                        currentRoute === routes[secondaryRouteName]
                            ? 'w-full px-2 py-4 cursor-pointer bg-neutral-gray-500 '
                            : 'w-full px-2 py-4 cursor-pointer '
                    }
                >
                    <img
                        src={
                            currentRoute === routes[routeName] ||
                            currentRoute === routes[secondaryRouteName]
                                ? imageRed
                                : imageGrey
                        }
                        width="25"
                        height="25"
                        className="mx-auto"
                        style={{ minHeight: '25px', minWidth: '25px' }}
                    />
                </div>
            </div>
        </React.Fragment>
    )
}

const Sidebar = (props) => {
    const history = useHistory()
    const [currentRoute, setCurrentRoute] = React.useState(
        history.location.pathname
    )
    const windowHeight = window.innerHeight - 77

    return (
        <React.Fragment>
            <OnMountTransition>
                <div
                    id="sidebar-main"
                    className="lg:fixed  left-0 overflow-y-auto new-sidebar-bg"
                    style={{
                        width: `${screenIs("lg") ? '5%' :'70%' }`,
                        top: '40px',
                        // background: '#2F3B52',
                        height: windowHeight,
                    }}
                >
                    <div className="mt-10">
                        {/* <div className="px-6 ">
                            <img src={OrgLogo} className="mx-auto" />
                            <div className="mt-2 flex items-center flex-wrap justify-center ">
                                <a
                                    href="#"
                                    className="cursor-pointer text-xl text-neutral-gray-100"
                                >
                                    Sunbank Inc
                                </a>
                                <a href="#" className="cursor-pointer ml-2 ">
                                    <img src={DropdownArrow} />
                                </a>
                            </div>
                            <div className="mt-2  text-center">
                                <div className="cursor-pointer text-base text-neutral-gray-100">
                                    9898 Trent Bypass Suite 541
                                </div>
                            </div>
                        </div> */}
                        <div className="mt-9 w-full">
                            <div
                                className="w-full flex items-center pb-4"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    history.push(routes.dashboard)
                                    setCurrentRoute(routes.dashboard)
                                }}
                            >
                                <div className="w-full px-2 py-4 cursor-pointer">
                                    <img
                                        src={LayerIcon}
                                        width="25"
                                        height="25"
                                        className="mx-auto"
                                        style={{
                                            minHeight: '25px',
                                            minWidth: '25px',
                                        }}
                                    />
                                </div>
                            </div>

                            <Section
                                routeName="/"
                                imageGrey={ManufacturingIcon}
                                imageRed={ManufacturingIcon}
                                sectionLabel=" My Team"
                                currentRoute={currentRoute}
                                setCurrentRoute={setCurrentRoute}
                            />
                            <Section
                                routeName="/"
                                imageGrey={TransitionIcon}
                                imageRed={TransitionIcon}
                                sectionLabel=" My Team"
                                currentRoute={currentRoute}
                                setCurrentRoute={setCurrentRoute}
                            />
                            <Section
                                routeName="/"
                                imageGrey={Distribution}
                                imageRed={Distribution}
                                sectionLabel=" My Team"
                                currentRoute={currentRoute}
                                setCurrentRoute={setCurrentRoute}
                            />
                            <Section
                                routeName="/"
                                imageGrey={TransitionIcon}
                                imageRed={TransitionIcon}
                                sectionLabel=" My Team"
                                currentRoute={currentRoute}
                                setCurrentRoute={setCurrentRoute}
                            />
                            <Section
                                routeName="/"
                                imageGrey={Restaurant}
                                imageRed={Restaurant}
                                sectionLabel=" My Team"
                                currentRoute={currentRoute}
                                setCurrentRoute={setCurrentRoute}
                            />
        
                        </div>
                    </div>
                </div>
            </OnMountTransition>
        </React.Fragment>
    )
}

export default Sidebar
