import React, { Suspense } from 'react'
import ErrorBoundary from './errorboundry'
import Spinner from 'components/common/spinner'

const ExpiredCasesPage = React.lazy(() => import('./expiredCases'))

const ExpiredCasesViewContainer = (props) => (
    <div>
        <Suspense fallback={<Spinner />}>
            <ErrorBoundary>
                <ExpiredCasesPage {...props} />
            </ErrorBoundary>
        </Suspense>
    </div>
)

export default ExpiredCasesViewContainer;
