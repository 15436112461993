import React, { Suspense } from 'react'
import ErrorBoundary from './errorboundary'
import Spinner from 'components/common/spinner'

const CaseHistoryPage = React.lazy(() => import('./casehistory'))


const CaseHistoryContainer = (props) => (
    <div>
        <Suspense fallback={<Spinner />}>
            <ErrorBoundary>
                <CaseHistoryPage {...props} />
            </ErrorBoundary>
        </Suspense>
    </div>
)

export default CaseHistoryContainer
