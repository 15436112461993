import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Auth0Provider } from '@auth0/auth0-react'
import history from './utils/history'

const onRedirectCallback = (appState) => {
    history.push(
        appState && appState.returnTo
            ? appState.returnTo
            : window.location.pathname
    )
}
ReactDOM.render(
        // <Auth0Provider
        //     domain={process.env.REACT_APP_AUTH0_DOMAIN}
        //     clientId={process.env.REACT_APP_AUTH0_CLIENTID}
        //     audience={process.env.REACT_APP_AUTH0_AUDIENCE}
        //     redirectUri={window.location.origin}
        //     onRedirectCallback={onRedirectCallback}
        //     scope="read:current_user"
        // >
           
        // </Auth0Provider>
        <>
        <App />
        </>,
    document.getElementById('root')
)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
