import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { ApolloClient, useLazyQuery, useQuery } from '@apollo/client'

// asset imports
import MenuIcon from 'assets/menu.png'
import CloseIcon from 'assets/close.svg'
import Iconcheck from 'assets/icon_check.png'
import HelpIcon from 'assets/help.png'
import EmailIcon from 'assets/email.png'
import NotificationBell from 'assets/notification_bell.png'
import DropdownArrow from 'assets/dropdown_arrow.png'
import Logo from 'logo.svg'
import OrgLogo from '../../../../assets/logo.png'
import SettingsIcon from 'assets/settings.svg'
import CalendarIcon from 'assets/calendar.png'
import ExpiredIcon from 'assets/expired.png'
import Notifications from './notifications'

// import InputComponent from '../../../common/input'
import {
    Input,
    Button,
    Spin,
    Menu,
    Dropdown,
    message,
    Space,
    Tooltip,
    Form,
    Select,
} from 'antd'

// css imports
import './header.css'
import UserMenu from './profileDropdown'
import { screenIs } from 'components/common/ScreenSize'
import { GET_CASE_BY_CASEID, GET_GTIN_DATA_FOR_SKU } from 'schema/queries'
import Spinner from 'components/common/spinner'
import { skuDataModel } from 'constants/dataModal'

const { Option } = Select

const SkuDropdown = ({ skuData, defaultValue }) => {
    return (
        <Form.Item>
            <Select placeholder="Select SKU" defaultValue={defaultValue}>
                {skuData.products_product
                    ? skuData.products_product.map((item, index) => {
                          return (
                              <Option key={item.gtin} value={item.gtin}>
                                  {item.label}
                                  {'  '} - {item.gtin}
                              </Option>
                          )
                      })
                    : null}
            </Select>
        </Form.Item>
    )
}

const Header = (props) => {
    const [inputValue, setInputValue] = useState('')
    const [showResultView, setShowResultView] = useState(false)
    const [gtin, setGtin] = useState([])
    const [refreshLoading, setRefreshLoading] = useState(false)

    // const [
    //     getSKUData,
    //     { loading: skuLoading, error: skuError, data: skuData },
    // ] = useLazyQuery(GET_GTIN_DATA_FOR_SKU)
    const [getCaseByCaseID, { loading, error, data }] =
        useLazyQuery(GET_CASE_BY_CASEID)
    const ref = useRef()
    const history = useHistory()

    useEffect(() => {
        // getSKUData()
        if (screenIs('md') && props.sidebarCollapsed === true) {
            props.toggleSidebar()
        }
    }, [screenIs('md')])
    // Detect click outside of the view
    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (
                showResultView &&
                ref.current &&
                !ref.current.contains(e.target)
            ) {
                setShowResultView(false)
            }
        }

        document.addEventListener('mousedown', checkIfClickedOutside)

        return () => {
            // Cleanup the event listener
            document.removeEventListener('mousedown', checkIfClickedOutside)
        }
    }, [showResultView])

    //   Detect  key press
    useEffect(() => {
        const listener = (event) => {
            setShowResultView(false)
            if (event.code === 'Enter' || event.code === 'NumpadEnter') {
                event.preventDefault()
                searchCaseHistory()
            }
        }
        document.addEventListener('keydown', listener)
        return () => {
            document.removeEventListener('keydown', listener)
        }
    }, [inputValue])

    // useEffect(() => {
    //     console.log(skuData);
    //     if (skuData) {
    //         setGtin(skuData)
    //     }
    // }, [skuData])

    useEffect(() => {
            if (skuDataModel) {
                setGtin(skuDataModel)
            }
        }, [skuDataModel])

    useEffect(() => {
        // refresh every 1 minute
        setInterval(() => {
            props.client.reFetchObservableQueries()
        }, 60000)
    }, [])

    const gotoCaseTimeline = () => {
        setShowResultView(false)
        setInputValue('')
        history.push({
            pathname: `/case`,
            search: `?id=${inputValue}`,
            state: { caseData: data },
        })
    }
    const searchCaseHistory = async () => {
        setShowResultView(true)
        await getCaseByCaseID({ variables: { caseId: inputValue } })
        if (data) {
            resultView()
        }
    }
    const resultView = () => {
        if (showResultView && !loading && data.cases_case.length === 0) {
            setTimeout(() => {
                setShowResultView(false)
            }, 2500)
        }
    }
    const silentRefresh = () => {
        // window.location.reload();
        setRefreshLoading(true)
        props.client.reFetchObservableQueries()
        setTimeout(() => {
            setRefreshLoading(false)
        }, 1500)
    }

    return (
        <div className="header-container  shadow-md h-12 2xl:h-16 new-sidebar-bg">
            <div className="h-full relative w-full flex justify-between">
                <div className="float-left h-full  flex items-center ">
                    {screenIs('lg') ? (
                        <div className="flex items-center py-3 mb-1">
                            <div className="w-full lg:pl-2 xl:pl-4 pl-6 font-extrabold">
                                <span className="text-center text-custom-blue md:text-base lg:text-xs xl:text-md 2xl:text-lg  font-bold">
                                    Supply Chain Insights
                                </span>
                            </div>
                        </div>
                    ) : null}
                    {!screenIs('md') ? (
                        <div
                            className="h-full w-3/12 md:w-1/12 md:ml-0 ml-2 cursor-pointer flex items-center justify-center"
                            onClick={(e) => {
                                e.stopPropagation()
                                props.toggleSidebar()
                            }}
                        >
                            <img
                                className="
                            w-5 h-4
                            2xl:w-6 2xl:h-5"
                                src={
                                    props.sidebarCollapsed
                                        ? MenuIcon
                                        : CloseIcon
                                }
                                alt="menu"
                            />
                        </div>
                    ) : null}
                    {screenIs('md') ? (
                        <div className="ml-10 w-20 mr-10">
                            {!refreshLoading ? (
                                <Button
                                    type="default"
                                    color="#3f3962"
                                    // loading={refreshLoading}
                                    onClick={() => silentRefresh()}
                                >
                                    Refresh
                                </Button>
                            ) : (
                                <div className="items-center justify-center">
                                    <Spin size="small" />
                                </div>
                            )}
                        </div>
                    ) : null}
                    {console.log(gtin)}
                    {screenIs('md') ? (
                        <>
                            <div style={{ width: '400px' }}>
                                {gtin?.products_product?.length && (
                                    <SkuDropdown
                                        skuData={gtin}
                                        defaultValue={
                                            gtin.products_product?.[0].gtin
                                        }
                                    />
                                )}
                            </div>
                            <div className="searchbar-container flex ml-4">
                                <div className="flex items-center justify-center">
                                    <div className="flex w-44 md:w-80 z-50">
                                        <Input
                                            placeholder="Enter Case ID"
                                            value={inputValue}
                                            onChange={(e) =>
                                                setInputValue(e.target.value)
                                            }
                                            // onPressEnter={(e) => {
                                            //     e.preventDefault()
                                            //     searchCaseHistory()
                                            // }}
                                        />
                                        <Button
                                            type="default"
                                            color="#3f3962"
                                            onClick={searchCaseHistory}
                                        >
                                            Search
                                        </Button>
                                    </div>
                                    <div className="absolute" ref={ref}>
                                        {showResultView && !loading ? (
                                            data &&
                                            data.cases_case.length === 0 ? (
                                                <div
                                                    onClick={() =>
                                                        setShowResultView(false)
                                                    }
                                                    className="mt-20 w-30 md:w-80 z-40 bg-purpal-sidebar"
                                                >
                                                    <span className="text-white p-2">
                                                        No case found for{' '}
                                                        {inputValue}.
                                                    </span>
                                                </div>
                                            ) : (
                                                <div
                                                    onClick={() =>
                                                        gotoCaseTimeline()
                                                    }
                                                    className="mt-20 w-30 md:w-80 z-40	bg-purpal-sidebar cursor-pointer"
                                                >
                                                    <span className="text-white p-2">
                                                        Click here to see result
                                                    </span>
                                                </div>
                                            )
                                        ) : null}
                                    </div>

                                    {showResultView && loading ? (
                                        <div className="absolute mt-20 w-30 md:w-80 z-40 bg-purpal-sidebar">
                                            <div className="text-white p-2 text-center">
                                                <Spin size="small" />
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </>
                    ) : !screenIs('md') && props.sidebarCollapsed === true ? (
                        <div className="searchbar-container ml-28">
                            <div className="flex items-center justify-center ">
                                <div className="flex w-44 md:w-fit absolute">
                                    <Input
                                        placeholder="Enter Case ID"
                                        value={inputValue}
                                        onChange={(e) =>
                                            setInputValue(e.target.value)
                                        }
                                        // onPressEnter={(e) => {
                                        //     e.preventDefault()
                                        //     searchCaseHistory()
                                        // }}
                                    />
                                    <Button
                                        type="default"
                                        color="#3f3962"
                                        onClick={searchCaseHistory}
                                    >
                                        Search
                                    </Button>
                                </div>
                                {showResultView && !loading ? (
                                    data && data.cases_case.length === 0 ? (
                                        <div
                                            onClick={() =>
                                                setShowResultView(false)
                                            }
                                            className="absolute mt-20 w-30 md:w-80 z-40	bg-white"
                                        >
                                            <span className="text-black p-2">
                                                No case found for {inputValue}.
                                            </span>
                                        </div>
                                    ) : (
                                        <div
                                            onClick={() => gotoCaseTimeline()}
                                            className="absolute mt-20 w-30 md:w-80 z-40	 bg-white cursor-pointer"
                                        >
                                            <span className="text-black p-2">
                                                Click here to see result
                                            </span>
                                        </div>
                                    )
                                ) : null}
                            </div>
                        </div>
                    ) : null}
                </div>
                <div className="flex items-center h-full right-0">
                    {screenIs('md') ? (
                        <div className="flex items-center mr-4">
                            <div
                                onClick={() =>
                                    history.push('/cases-by-time-range')
                                }
                                className="mr-4 cursor-pointer"
                            >
                                <div className="rounded">
                                    <Tooltip title="View cases by time range">
                                        <img
                                            src={CalendarIcon}
                                            className="
                                                w-7 h-7
                                                2xl:w-7 2xl:h-7"
                                            alt="View cases by time range"
                                        />
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                    ) : null}
                    {screenIs('md') ? (
                        <div className="flex items-center mr-4">
                            <div
                                onClick={() => history.push('/expiredcases')}
                                className="mr-4 cursor-pointer"
                            >
                                <div className="rounded">
                                    <Tooltip title="Expired cases report">
                                        <img
                                            src={ExpiredIcon}
                                            className="
                                                w-7 h-7
                                                2xl:w-7 2xl:h-7"
                                            alt="Expired cases report"
                                        />
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                    ) : null}
                    <div className="float-right h-full flex items-center justify-center pr-8 ">
                        <div className="mr-2">
                            <Notifications />
                        </div>
                        <div
                            onClick={() => history.push('/products')}
                            className="cursor-pointer mr-4"
                        >
                            <Tooltip title="View Products">
                                <img
                                    src={SettingsIcon}
                                    className="
                                            w-5 h-4
                                            2xl:w-6 2xl:h-5"
                                    alt="settings"
                                />
                            </Tooltip>
                        </div>
                        <div className="items-center">
                            <UserMenu
                            // userDetails={userDetails?.user[0]}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header
