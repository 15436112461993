import React, { useState, useEffect } from 'react'
import { Table, Tooltip } from 'antd'
import Spinner from './spinner'

const AllCasesTable = ({
    data = [],
    loading = true,
    totalCount = 0,
    caseLoading = false,
    visible = false,
    columns,
    tableTitle = '',
    setCaseId = () => {},
    setOffsetValue =() => {},
    setShowCasesModal = () => {},
    setCasesModalHeader = () => {},
}) => {
    const [currentPage, setCurrentPage]= React.useState(1) 
    return (
        <>
            {data && !loading ? (
                <div className="mt-2">
                    <div className="text-lg 2xl:text-2xl  text-white">
                        {tableTitle}
                    </div>
                    <div className="mt-2">
                        <Table
                            columns={columns}
                            loading={{ indicator: <Spinner />, spinning: loading }}
                            dataSource={data}
                            rowKey="serialNumber"
                            onRow={(record, rowIndex) => {
                                return {
                                    onClick: (event) => {
                                        event.preventDefault();
                                        event.stopPropagation();
                                        let id = record.serialNumber.toString()
                                        setCaseId(id)
                                    }, // click row
                                    onMouseEnter: event => {
                                        return(
                                            <Tooltip title="View timeline"></Tooltip>
                                        )
                                    },
                                }
                            }}
                            pagination={{
                                total: totalCount,
                                current:　currentPage,
                                onChange: (page, pageSize) => {
                                    setCurrentPage(page);
                                    setOffsetValue((page*10) - 10)
                                }
                            }}
                        />
                    </div>
                    {
                        caseLoading ? 
                            <div className="fixed top-2/4 left-2/4 translate-y-2/4 translate-x-2/4">
                                <Spinner />
                            </div> 
                            : null
                    }
                </div>
            ) : (
                <Spinner />
            )}
        </>
    )
}

export default AllCasesTable
