import React, { Suspense } from 'react'
import ErrorBoundaryCasesTimeRange from './errorboundary'
import Spinner from 'components/common/spinner'

const CasesTimeRangePage = React.lazy(() => import('./casestimerange'))


const CasesTimeRangeContainer = (props) => (
    <div>
        <Suspense fallback={<Spinner />}>
            <ErrorBoundaryCasesTimeRange>
                <CasesTimeRangePage {...props} />
            </ErrorBoundaryCasesTimeRange>
        </Suspense>
    </div>
)

export default CasesTimeRangeContainer
