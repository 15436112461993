import React, { Suspense } from 'react'
import ErrorBoundary from './errorboundry'
import Spinner from 'components/common/spinner'

const ProductPage = React.lazy(() => import('./product'))

const DashboardViewContainer = (props) => (
    <div>
        <Suspense fallback={<Spinner />}>
            <ErrorBoundary>
                <ProductPage {...props} />
            </ErrorBoundary>
        </Suspense>
    </div>
)

export default DashboardViewContainer
