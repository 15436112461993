import React, { useState } from 'react'
import { Table } from 'antd'
import Spinner from './spinner'
import { numberWithCommas } from '../../utils/misc'

const CteDetailsTable = ({
    data,
    loading = false,
    columns,
    tableTitle = '',
    setId = () => {},
    setShowCasesModal = () => {},
    setCasesModalHeader = () => {},
}) => {
    return (
        <>
            {data && !loading ? (
                <div className="mt-0">
                    <div className="text-lg 2xl:text-2xl  text-white">
                        {tableTitle}
                    </div>
                    <div className="mt-2">
                        <Table
                            columns={columns}
                            loading={{
                                indicator: <Spinner />,
                                spinning: loading,
                            }}
                            rowKey="place_id"
                            dataSource={(data)}
                            onRow={(record, rowIndex) => {
                                return {
                                    onClick: (event) => {
                                        event.preventDefault()
                                        event.stopPropagation()
                                        let id = record.site.toString()
                                        setId(id)
                                        setShowCasesModal(true)
                                        setCasesModalHeader(record.displayName !== null ? record.displayName : 'N/A')
                                    }, // click row
                                }
                            }}
                        />
                    </div>
                </div>
            ) : (
                <Spinner />
            )}
        </>
    )
}

export default CteDetailsTable
