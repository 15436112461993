import React from 'react'
import { Spin } from 'antd'

const Spinner = ({spinSize="large"}) => {
    return (
        <>
            <div className="w-full h-full px-8 py-8 flex items-center">
                <div className="w-full text-center">
                    <Spin size={spinSize} />
                    <div className="mt-2 text-primary-red-500 font-bold">
                        Loading ...
                    </div>
                </div>
            </div>
        </>
    )
}

export default Spinner
