import { useLazyQuery, useQuery } from '@apollo/client'
import { Menu, Tooltip } from 'antd'
import LayerIcon from 'assets/layer-icon.png'
import ib from 'assets/sidebar/1b.png'
import i3 from 'assets/sidebar/3.png'
import i4 from 'assets/sidebar/4.png'
import i5 from 'assets/sidebar/5.png'
import i6 from 'assets/sidebar/6.png'
import OnMountTransition from 'components/common/OnMountTransition'
import { dataModel, siteDataModel, singleCaseModel, casesDataModel } from 'constants/dataModal'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
    GET_ALL_COUNT_PER_CTE,
    GET_CASES_AND_COUNT_BY_SITE_ID,
    GET_CASE_BY_CASEID,
    GET_CTE_DATA_BY_EVENT_ID,
    GET_LOCATION_NAME_BY_SITE_ID,
} from 'schema/queries'
import i1 from '../../../assets/sidebar/1.png'
import routes from '../../../routes'
import { numberWithCommas } from '../../../utils/misc'
import ModalContainer from '../../common/modal'
import AllCasesTable from '../allCasesTable'
import CteDetailsTable from '../cteDetailsTable'
import { screenIs } from '../ScreenSize'
import './sidebar.css'

const { SubMenu } = Menu

const cteTableColumn = [
    {
        key: 1,
        title: 'Site',
        dataIndex: 'site',
        className: 'cursor-pointer',
    },
    {
        key: 2,
        title: 'Site detail',
        dataIndex: 'site_detail',
        className: 'cursor-pointer',
    },
    {
        key: 3,
        title: 'Count',
        className: 'cursor-pointer',
        render: (item) => {
            return <span>{numberWithCommas(item.total.aggregate.count)}</span>
        },
    },
    {
        key: 4,
        title: 'Cases at risk',
        className: 'cursor-pointer',
        render: (item) => {
            return (
                <span>
                    {numberWithCommas(item.casesAtRisk.aggregate.count)}
                </span>
            )
        },
    },
]
const casesTableColumn = [
    {
        key: 1,
        title: 'Serial number',
        dataIndex: 'serialNumber',
        className: 'cursor-pointer',
    },
    {
        key: 2,
        title: 'Shelf life remaining',
        // dataIndex: 'shelfLifeRemaining',
        render: (text) => {
            const minutesToDhms = (minutes) => {
                minutes = Number(minutes)
                var d = Math.floor(minutes / 60 / 24)
                var h = Math.floor(minutes / 60 - d * 24)
                var m = Math.floor(minutes - (d * 24 * 60 + h * 60))

                var dDisplay = d > 0 ? d + (d == 1 ? ' Day ' : ' Days ') : ''
                var hDisplay = h > 0 ? h + (h == 1 ? ' Hour ' : ' Hours ') : ''
                var mDisplay =
                    m > 0 ? m + (m == 1 ? ' Minute ' : ' Minutes ') : ''
                return dDisplay + hDisplay + mDisplay
            }
            let shelfLife = minutesToDhms(text.shelfLifeRemaining)
            return (
                <span>
                    {
                        (text.shelfLifeRemaining && text.shelfLifeRemaining >= 0
                            ? shelfLife
                            : 'expired since: ',
                        shelfLife)
                    }
                </span>
            )
        },
    },
]

const Section = ({
    toggleSidebar = () => {},
    routeName,
    secondaryRouteName,
    // imageGrey,
    cteData,
    siteNameData = [],
    eventId,
    imageRed,
    sectionLabel,
    spanLabel,
    sectionNumber,
    currentRoute,
    setCurrentRoute,
    isComingSoon = false,
    setShowModal = () => {},
    onSelectCTE = () => {},
}) => {
    const history = useHistory()
    const location = useLocation()
    const [selectedID, setSelectedID] = useState('')

    const params = new URLSearchParams(location.search)
    const routeID = params.get('eventId')

    useEffect(() => {
        if (routeID) {
            history.push({
                pathname: `/`,
            })
        }
    }, [])

    let imagePath
    switch (true) {
        case eventId === '1':
            imagePath = i1
            break
        case eventId === '2':
            imagePath = ib
            break
        case eventId === '3':
            imagePath = i3
            break
        case eventId === '4':
            imagePath = i4
            break
        case eventId === '5':
            imagePath = i5
            break
        default:
            imagePath = i6
            break
    }

    const groups =
        cteData.deviceLocations_aggregate &&
        cteData.deviceLocations_aggregate.nodes.reduce((groups, item) => {
            let res
            let obj = {}
            const group = groups[item.site] || []
            if (siteNameData.length > 0) {
                res = siteNameData.filter((x) => x.site_code == item.site)
            }
            // Create copy of object
            obj = { ...item }
            obj.displayName = res && res[0] ? res[0].displayName : null
            group.push(obj)
            groups[item.site] = group
            return groups
        }, {})
    let eventIdParam
    switch (true) {
        case sectionLabel === 'Manufacturing':
            eventIdParam = '1b'
            break
        case sectionLabel === 'In-Transit (Distribution)':
            eventIdParam = '2'
            break
        case sectionLabel === 'Distribution':
            eventIdParam = '3'
            break
        case sectionLabel === 'In Transit (Restaurant)':
            eventIdParam = '4'
            break
        case sectionLabel === 'Restaurant':
            eventIdParam = '5,6'
            break
        default:
            eventIdParam = null
            break
    }

    return (
        <React.Fragment>
            <div className="flex overflow-y-scroll">
                <Menu
                    defaultSelectedKeys={[]}
                    defaultOpenKeys={[]}
                    mode="inline"
                    className={`${
                        selectedID == routeID ? 'menu-container' : ''
                    }`}
                >
                    <SubMenu
                        className={` ${
                            cteData.containsCasesAtRisk
                                ? 'border-r-4 border-yellow-400'
                                : ''
                        }`}
                        icon={
                            <img
                                src={imagePath}
                                className="w-5 h-5 2xl:w-6 2xl:h-6"
                            />
                        }
                        title={
                            <>
                                <div className="grid py-4">
                                    <Tooltip title="View at risk chart">
                                        <span
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                // setSelectedID(id);

                                                history.push({
                                                    pathname: `/`,
                                                    search: `?eventId=${eventIdParam}`,
                                                })
                                                setSelectedID(eventIdParam)
                                            }}
                                            className={`${
                                                selectedID == routeID
                                                    ? 'text-black'
                                                    : 'text-white'
                                            } 'text-sm font-semibold md:text-lg lg:text-sm xl:text-base 2xl:text-base'`}
                                            // className="text-sm font-semibold md:text-lg lg:text-sm xl:text-base 2xl:text-base"
                                        >
                                            {sectionLabel}
                                        </span>
                                    </Tooltip>
                                    <span
                                        className={`${
                                            selectedID == routeID
                                                ? 'text-blue-600'
                                                : 'text-white'
                                        } 'text-sm font-semibold md:text-lg lg:text-sm xl:text-base 2xl:text-base'`}
                                        // className="text-sm font-semibold md:text-lg lg:text-sm xl:text-base 2xl:text-base"
                                    >
                                        {numberWithCommas(sectionNumber)}
                                    </span>
                                </div>
                            </>
                        }
                    >
                        {/* {eventId === '1' ? (
                            <SubMenu title="Opelika">
                                <Menu.Item style={{paddingLeft: '25px'}}>
                                    <div className="flex justify-between">
                                        <span>Daily Count</span>
                                        <span>
                                            {cteData.today
                                                ? cteData.today.aggregate.count
                                                : ''}
                                        </span>
                                    </div>
                                </Menu.Item>
                            </SubMenu>
                        ) : null} */}

                        {Object.keys(groups).length > 0
                            ? Object.keys(groups) &&
                              Object.keys(groups).map((k, index) => {
                                  let allData = []
                                  let total = 0
                                  let todayTotal = 0
                                  let displayName = 'N/A'
                                  let siteId
                                  groups[k].map((d) => {
                                      total +=
                                          d.currentCases_aggregate.aggregate
                                              .count
                                      displayName =
                                          d.displayName !== null
                                              ? d.displayName
                                              : displayName
                                      if (d.eventId && d.eventId == 5) {
                                          todayTotal += d.today.aggregate.count
                                      } else if (!d.eventId) {
                                          todayTotal += d.today.aggregate.count
                                      }
                                      eventId = d.eventId ? d.eventId : ''
                                      siteId = d.site
                                  })
                                  allData.push({
                                      key: displayName,
                                      total: total,
                                      todayTotal: todayTotal,
                                      eventId: eventId,
                                      siteId: siteId,
                                  })
                                  return (
                                      <SubMenu
                                          key={index}
                                          title={
                                              <>
                                                  <div className="flex justify-between items-center">
                                                      <Tooltip title="Show cases">
                                                          <span
                                                              className={`${
                                                                  selectedID ==
                                                                  routeID
                                                                      ? 'text-black'
                                                                      : 'text-white'
                                                              } 'text-sm font-semibold md:text-lg lg:text-sm xl:text-base 2xl:text-base'`}
                                                              //   className="text-sm font-semibold md:text-lg lg:text-sm xl:text-base 2xl:text-base"
                                                              onClick={(e) => {
                                                                  e.stopPropagation()
                                                                  onSelectCTE(
                                                                      allData[0]
                                                                          .key,
                                                                      allData[0]
                                                                          .siteId
                                                                  )
                                                              }}
                                                          >
                                                              {(eventIdParam ===
                                                                  '2' ||
                                                                  eventIdParam ===
                                                                      '4') &&
                                                                  'From'}
                                                              &nbsp;
                                                              {allData[0].key}
                                                          </span>
                                                      </Tooltip>

                                                      {/* <span>{eventId !== '1' ? allData[0].total : allData[0].todayTotal}</span> */}
                                                      <span
                                                          className={`${
                                                              selectedID ==
                                                              routeID
                                                                  ? 'text-blue-600'
                                                                  : 'text-white'
                                                          } 'text-sm font-semibold md:text-lg lg:text-sm xl:text-base 2xl:text-base'`}
                                                          //   className="text-sm font-semibold md:text-lg lg:text-sm xl:text-base 2xl:text-base py-2"
                                                      >
                                                          {numberWithCommas(
                                                              allData[0].total
                                                          )}
                                                      </span>
                                                  </div>
                                              </>
                                          }
                                      >
                                          <Menu.Item
                                              style={{ paddingLeft: '25px' }}
                                              // onClick={() =>
                                              //     onSelectCTE(
                                              //         item.site_detail,
                                              //         item.place_id
                                              //     )
                                              // }
                                              key={index}
                                          >
                                              <div className="flex justify-between">
                                                  <span>
                                                      {allData[0].eventId ==
                                                          5 ||
                                                      allData[0].eventId == 6
                                                          ? 'Arrived Today'
                                                          : 'Daily Count'}
                                                  </span>
                                                  <span>
                                                      {numberWithCommas(
                                                          allData[0].todayTotal
                                                      )}
                                                  </span>
                                              </div>
                                          </Menu.Item>
                                          {/* {groups[k].map((item, index) => {
                                            return (
                                                <Menu.Item
                                                    style={{paddingLeft: '25px'}}
                                                    onClick={() =>
                                                        onSelectCTE(
                                                            item.site_detail,
                                                            item.place_id
                                                        )
                                                    }
                                                    key={index}
                                                >
                                                    <div className="flex justify-between">
                                                        <span>
                                                            {item.site_detail}
                                                        </span>
                                                        <span>
                                                            {eventId !== '1'
                                                                ? item.currentCases_aggregate.aggregate.count
                                                                : item.today.aggregate.count}
                                                        </span>
                                                    </div>
                                                </Menu.Item>
                                            )
                                        })} */}
                                      </SubMenu>
                                  )
                              })
                            : null}
                    </SubMenu>
                </Menu>
            </div>
        </React.Fragment>
    )
}

const Sidebar = ({ props, toggleSidebar = () => {} }) => {
    const history = useHistory()
    const [currentRoute, setCurrentRoute] = React.useState(
        history.location.pathname
    )
    const [totalSearch, setTotalSearch] = React.useState('')
    const [casesModalHeader, setCasesModalHeader] = React.useState('')
    const [caseId, setCaseId] = React.useState('')
    // const [placeId, setPlaceId] = React.useState('')
    const [siteId, setSiteId] = React.useState('')

    const [today, setToday] = React.useState(
        moment(new Date()).tz('America/Chicago').format('YYYY-MM-DD')
    )
    const [yesterday, setYesterday] = React.useState(
        moment(new Date(new Date().valueOf() - 1000 * 60 * 60 * 24))
            .tz('America/Chicago')
            .format('YYYY-MM-DD')
    )

    const [showModal, setShowModal] = React.useState(false)
    const [showCasesModal, setShowCasesModal] = React.useState(false)
    const [modalHeader, setModalHeader] = React.useState(false)

    const [sidebarData, setSidebarData] = React.useState([])
    const [sidebarRestaurantData, setSidebarRestaurantData] = React.useState([])
    const [siteNameData, setSiteNameData] = React.useState([])

    const [singleCteData, setSingleCteData] = React.useState([])
    const [allCasesData, setAllCasesdata] = React.useState([])

    const [cteId, setCteId] = React.useState(['1'])
    const [routeId, setRouteId] = React.useState('')

    const [offsetValue, setOffsetValue] = React.useState(0)

    const versionInfo = process.env.REACT_APP_VERSION_INFO
    const maxHeight = window.innerHeight - 50
    const location = useLocation()

    // const { loading, error, data } = useQuery(GET_ALL_COUNT_PER_CTE, {
    //     variables: { gtin: ['500539', '500406'], today: today },
    // })
    // const {
    //     loading: siteLoading,
    //     error: siteError,
    //     data: siteData,
    // } = useQuery(GET_LOCATION_NAME_BY_SITE_ID)
    // get case by case Id
    // const [getCaseByCaseID, { loading: caseLoading, data: singleCase }] =
    //     useLazyQuery(GET_CASE_BY_CASEID)

    const [getCteDataById, { loading: isLoading, data: cteData }] =
        useLazyQuery(GET_CTE_DATA_BY_EVENT_ID, {
            variables: {
                eventId: cteId,
                total: {
                    expiryTimestamp: {
                        _gt: 'now()',
                    },
                },
                casesAtRisk: {
                    isCaseAtRisk2: {
                        _eq: true,
                    },
                    expiryTimestamp: {
                        _gt: 'now()',
                    },
                    isTracked: {
                        _eq: true,
                    },
                },
            },
        })
    // get cases by site id query
    // const [
    //     getCasesBySiteId,
    //     { loading: isCasesLoading, refetch, data: casesData },
    // ] = useLazyQuery(GET_CASES_AND_COUNT_BY_SITE_ID, {
    //     variables: {
    //         where: {
    //             expiryTimestamp: {
    //                 _gt: 'now()',
    //             },
    //             isTracked: {
    //                 _eq: true,
    //             },
    //             site: {
    //                 _eq: siteId,
    //             },
    //         },
    //         offset: offsetValue,
    //         limit: 10,
    //     },
    // })

    useEffect(() => {
        const params = new URLSearchParams(location.search)
        const route = params.get('eventId')
        setRouteId(route)
    }, [location])
    //For datamodel
    useEffect(() => {
        if (dataModel) {
            var sidebar = []
            let restaurantArr = []
            let sidebarArr = dataModel.ctes ? dataModel.ctes : []
            sidebar = sidebarArr.filter((x) => {
                return x.eventId != '5' && x.eventId != '6'
            })
            setSidebarData(sidebar)
            // To get restaurant arr
            restaurantArr = sidebarArr.filter((x) => {
                return x.eventId == '5' || x.eventId == '6'
            })

            let newArray = JSON.parse(JSON.stringify(restaurantArr))
            newArray[0].deviceLocations_aggregate.nodes =
                newArray[0].deviceLocations_aggregate.nodes.map((v) => ({
                    ...v,
                    eventId: 5,
                }))
            newArray[1].deviceLocations_aggregate.nodes =
                newArray[1].deviceLocations_aggregate.nodes.map((v) => ({
                    ...v,
                    eventId: 6,
                }))
            // merge nodes into one
            newArray[0].deviceLocations_aggregate.nodes =
                newArray[0].deviceLocations_aggregate.nodes.concat(
                    newArray[1].deviceLocations_aggregate.nodes
                )
            // // merge total count into one
            // newArray[0].total.aggregate.count =
            //     newArray[0].total.aggregate.count +
            //     newArray[1].total.aggregate.count
            restaurantArr = newArray.filter((x) => {
                return x.eventId == '5'
            })

            setSidebarRestaurantData(restaurantArr)
            getTotalCount()
        }
    }, [dataModel])
    useEffect(() => {
        if (casesDataModel) {
            setAllCasesdata(casesDataModel)
        }
    }, [casesDataModel])

    useEffect(() => {
        if (siteDataModel) {
            setSiteNameData(
                siteDataModel.locations_site ? siteDataModel.locations_site : []
            )
        }
    }, [siteDataModel])

    // useEffect(() => {
    //     console.log("cteData", cteData);
    //     if (cteData) {
    //         setSingleCteData(
    //             cteData.cte_event !== null
    //                 ? cteData.cte_event.deviceLocations_aggregate.nodes
    //                 : []
    //         )
    //     }
    // }, [cteData])

    // useEffect(() => {
    //     getCasesBySiteId()
    // }, [siteId, offsetValue])

    // call when case id changes
    // useEffect(() => {
    //     getCaseHistorybyId(caseId)
    // }, [caseId])
    // call when case id changes
    useEffect(() => {
        if (singleCaseModel && singleCaseModel.cases_case.length > 0) {
            window.open(`/case?id=${caseId}`, '_blank')
            setCaseId('')
        }
    }, [singleCaseModel])

    // const getCaseHistorybyId = async (id) => {
    //     await getCaseByCaseID({ variables: { caseId: caseId } })
    // }
    const getTotalCount = () => {
        setTimeout(() => {
            let total = 0
            dataModel.ctes.forEach((item, index) => {
                if (item.eventId == '1') {
                    return
                }
                item.deviceLocations_aggregate.nodes.forEach((item, index) => {
                    return (total +=
                        item.currentCases_aggregate.aggregate.count)
                })
            })
            setTotalSearch(numberWithCommas(total))
        }, 500)
    }

    const closeModal = () => {
        setShowModal(false)
    }
    const closeCasesModal = () => {
        setShowCasesModal(false)
        setOffsetValue(0)
    }
    const handleCTESelect = async (cteValue, id) => {
        setShowCasesModal(true)
        await setSiteId(id)
        setCasesModalHeader(cteValue)
        console.log('handleCTESelect', cteValue, id)
        // refetch();
    }
    return (
        <React.Fragment>
            <OnMountTransition>
                <div
                    id="sidebar-main"
                    className="w-screen h-full lg:w-1/5
                lg:fixed  left-0  new-dashboard-bg"
                >
                    <div className="mt-1">
                        <div className="w-full">
                            <div
                                className={`${
                                    routeId
                                        ? 'total-supply-notselected'
                                        : 'total-supply-selected'
                                } w-full flex items-center pb-4 mb-1`}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    if (!screenIs('md')) {
                                        toggleSidebar()
                                    }
                                    history.push(routes.dashboard)
                                    setCurrentRoute(routes.dashboard)
                                }}
                            >
                                <div
                                    className={
                                        currentRoute === routes.dashboard
                                            ? `w-full flex items-center px-5 lg:px-2 xl:px-6 py-1 cursor-pointer`
                                            : `w-full flex items-center px-5 lg:px-2 xl:px-6 py-1 cursor-pointer `
                                    }
                                >
                                    <div className="w-2/12">
                                        <img
                                            src={LayerIcon}
                                            className=" 
                                            w-5 h-5
                                            2xl:w-6 2xl:h-6"
                                        />
                                    </div>
                                    <div className=" w-8/12 lg:pl-1 xl:pl-1 pl-1 text-sm md:text-lg lg:text-sm xl:text-base 2xl:text-base font-extrabold">
                                        <span
                                            className={`${
                                                routeId !== null
                                                    ? 'text-white'
                                                    : 'text-black'
                                            } align-text-top font-bold`}
                                        >
                                            Total Supply Chain
                                        </span>
                                    </div>
                                    <div className=" md:w-3/12 w-4/12 lg:pl-3 xl:pl-0 pl-6 text-primary-red-500 text-md md:text-xl lg:text-xs xl:text-md 2xl:text-base">
                                        <span
                                            className={`${
                                                routeId !== null
                                                    ? 'text-white'
                                                    : 'text-blue-600'
                                            } align-text-top font-bold`}
                                        >
                                            {totalSearch}
                                        </span>
                                        <br />
                                        <span
                                            className={`${
                                                routeId !== null
                                                    ? 'text-white'
                                                    : 'text-blue-600'
                                            } align-text-top font-bold`}
                                        >
                                            Cases
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="absolute overflow-x-scroll h-full w-full pb-44">
                                {sidebarData.length > 0
                                    ? sidebarData.map((item, index) => {
                                          let imagePath = i1
                                          switch (true) {
                                              case item.eventId === '1':
                                                  return
                                              default:
                                                  break
                                          }
                                          let total = 0
                                          item.deviceLocations_aggregate &&
                                              item.deviceLocations_aggregate.nodes.map(
                                                  (d) => {
                                                      total +=
                                                          d
                                                              .currentCases_aggregate
                                                              .aggregate.count
                                                  }
                                              )
                                          return (
                                              <div key={index}>
                                                  <Section
                                                      toggleSidebar={
                                                          toggleSidebar
                                                      }
                                                      routeName="/"
                                                      eventId={item.eventId}
                                                      imageRed={imagePath}
                                                      sectionLabel={item.label}
                                                      sectionNumber={total}
                                                      siteNameData={
                                                          siteNameData
                                                      }
                                                      cteData={item}
                                                      currentRoute={
                                                          currentRoute
                                                      }
                                                      setCurrentRoute={
                                                          setCurrentRoute
                                                      }
                                                      setShowModal={
                                                          setShowModal
                                                      }
                                                      onSelectCTE={
                                                          handleCTESelect
                                                      }
                                                  />
                                              </div>
                                          )
                                      })
                                    : null}
                                {sidebarRestaurantData.length > 0
                                    ? sidebarRestaurantData.map(
                                          (item, index) => {
                                              let imagePath = i1
                                              switch (true) {
                                                  //   case item.eventId === '1':
                                                  //       return
                                                  default:
                                                      break
                                              }
                                              let total = 0
                                              item.deviceLocations_aggregate &&
                                                  item.deviceLocations_aggregate.nodes.map(
                                                      (d) => {
                                                          total +=
                                                              d
                                                                  .currentCases_aggregate
                                                                  .aggregate
                                                                  .count
                                                      }
                                                  )
                                              return (
                                                  <div key={index}>
                                                      <Section
                                                          toggleSidebar={
                                                              toggleSidebar
                                                          }
                                                          routeName="/"
                                                          eventId={item.eventId}
                                                          imageRed={imagePath}
                                                          sectionLabel={
                                                              item.label
                                                          }
                                                          sectionNumber={numberWithCommas(
                                                              total
                                                          )}
                                                          cteData={item}
                                                          siteNameData={
                                                              siteNameData
                                                          }
                                                          currentRoute={
                                                              currentRoute
                                                          }
                                                          setCurrentRoute={
                                                              setCurrentRoute
                                                          }
                                                          setShowModal={
                                                              setShowModal
                                                          }
                                                          onSelectCTE={
                                                              handleCTESelect
                                                          }
                                                      />
                                                  </div>
                                              )
                                          }
                                      )
                                    : null}
                            </div>
                            {/* Modal to show CTE details */}
                            <ModalContainer
                                bodyStyle={{ background: '#2f2a47' }}
                                visible={showModal}
                                width={`${
                                    screenIs('md') ? '800px' : 'w-screen'
                                }`}
                                closeModal={closeModal}
                                closable={true}
                            >
                                <div className="mt-2">
                                    <CteDetailsTable
                                        data={singleCteData}
                                        loading={false}
                                        columns={cteTableColumn}
                                        setId={setSiteId}
                                        setShowCasesModal={setShowCasesModal}
                                        setCasesModalHeader={
                                            setCasesModalHeader
                                        }
                                        tableTitle={modalHeader}
                                    />
                                </div>
                            </ModalContainer>
                            {/* Modal to show all cases by place id */}
                            <ModalContainer
                                bodyStyle={{ background: '#2f2a47' }}
                                visible={showCasesModal}
                                width={`${
                                    screenIs('md') ? '800px' : 'w-screen'
                                }`}
                                closeModal={closeCasesModal}
                                closable={true}
                            >
                                <div className="mt-0">
                                    <AllCasesTable
                                        data={
                                            allCasesData.cases_case_aggregate
                                                ? allCasesData
                                                      .cases_case_aggregate
                                                      .nodes
                                                : []
                                        }
                                        totalCount={
                                            allCasesData.cases_count
                                                ? allCasesData.cases_count
                                                      .aggregate.count
                                                : 0
                                        }
                                        columns={casesTableColumn}
                                        setCaseId={setCaseId}
                                        setOffsetValue={setOffsetValue}
                                        loading={false}
                                        caseLoading={false}
                                        tableTitle={casesModalHeader}
                                    />
                                </div>
                            </ModalContainer>
                        </div>
                    </div>
                </div>
            </OnMountTransition>
        </React.Fragment>
    )
}

export default Sidebar
