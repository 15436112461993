import React, { useState } from 'react'
import { Popover } from 'antd'

import DotMenuIcon from 'assets/dot_menu.png'

const MenuPopupWrapper = ({ menuContent, children, trigger = 'click' }) => {
    const [showMenu, toggleMenu] = useState(false)

    const toggleMenuVisibility = (visible) => {
        toggleMenu(visible)
    }

    const hideMenu = () => {
        toggleMenu(false)
    }
    return (
        <Popover
            content={() => (
                <div
                    onClick={(e) => {
                        e.stopPropagation()
                        hideMenu()
                    }}
                >
                    {menuContent}
                </div>
            )}
            // on
            placement="bottom"
            color="#2F3B52"
            trigger={trigger}
            visible={showMenu}
            onVisibleChange={toggleMenuVisibility}
        >
            {children ? (
                <div>{children}</div>
            ) : (
                <img
                    className="mx-auto px-4 py-2 2xl:py-3 cursor-pointer menu-popup"
                    alt="menu"
                    src={DotMenuIcon}
                />
            )}
        </Popover>
    )
}

export default MenuPopupWrapper
