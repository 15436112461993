import { gql } from '@apollo/client'

export const GET_AT_RISK_COUNT_PER_CTE = gql`
    query GET_AT_RISK_COUNT_PER_CTE($gtin: [String!]!) {
        ctes: cte_event(order_by: { position: desc }) {
            label
            currentCases_aggregate(
                where: {
                    gtin: { _in: $gtin }
                    isCaseExpired: { _eq: false }
                    isTracked: { _eq: true }
                    isCaseAtRisk2: { _eq: true }
                }
            ) {
                aggregate {
                    count
                }
            }
            eventId
        }
    }
`
export const GET_ALL_COUNT_PER_CTE = gql`
    query GET_ALL_COUNT_PER_CTE($gtin: [String!]!, $today: timestamptz!) {
        ctes: cte_event(order_by: { position: desc }) {
            label
            eventId
            containsCasesAtRisk
            deviceLocations_aggregate {
                aggregate {
                    count
                }
                nodes {
                    site_detail
                    site
                    place_id
                    currentCases_aggregate(
                        where: {
                            gtin: { _in: $gtin }
                            isCaseExpired: { _eq: false }
                        }
                    ) {
                        aggregate {
                            count
                        }
                    }
                    today: currentCases_aggregate(
                        where: {
                            isCaseExpired: { _eq: false }
                            updated_at: { _gte: $today }
                        }
                    ) {
                        aggregate {
                            count
                        }
                    }
                }
            }
        }
    }
`

export const GET_ALL_COUNT_PER_CTE_DASHBOARD = gql`
    query GET_ALL_COUNT_PER_CTE($gtin: [String!]!) {
        ctes: cte_event(order_by: { position: desc }) {
            label
            eventId
            deviceLocations_aggregate {
                nodes {
                    currentCases_aggregate(
                        where: {
                            gtin: { _in: $gtin }
                            isCaseExpired: { _eq: false }
                        }
                    ) {
                        aggregate {
                            count
                        }
                    }
                }
            }
        }
    }
`
export const GET_CASE_BY_CASEID = gql`
    query GET_CASE_BY_CASEID($caseId: String!) {
        cases_case(where: { serialNumber: { _eq: $caseId } }) {
            id
            isActive
            palletId
            pingsAtLocation
            shelfLifeRemaining
            updated_at
            created_at
            current_place_id
            currentDeviceLocation {
                city
                site_detail
                site
            }
            currentCteId
            caseHistories(order_by: { lastKnownLocationTime: desc }) {
                id
                cteId
                pingsAtLocation
                lastKnownLocationTime
                place_id
                timeSinceLastLocationChange
                deviceLocation {
                    city
                    site_detail
                    site
                }
            }
        }
    }
`

export const GET_AVG_SHELF_LIFE = gql`
    query GET_AVG_SHELF_LIFE($params: jsonb!, $gtin: [String!]!) {
        products_product(where: { gtin: { _in: $gtin } }) {
            casesByShelfLifeinDaysPerCte(args: { params: $params })
            averageShelfLife
            caseValue
            shelfLife
        }
    }
`
// cases by location nodes
export const GET_CASES_BY_LOCATION = gql`
    query GET_CASES_BY_LOCATION($eventId: String!) {
        cte_event_by_pk(eventId: $eventId) {
            deviceLocations_aggregate {
                aggregate {
                    count
                }
                nodes {
                    site_detail
                    site
                    currentCases_aggregate(
                        where: {
                            isTracked: { _eq: true }
                            isCaseExpired: { _eq: false }
                        }
                    ) {
                        aggregate {
                            count
                        }
                    }
                }
            }
        }
    }
`

// get CTE data
export const GET_CTE_DATA_BY_EVENT_ID = gql`
    query GET_CTE_DATA_BY_EVENT_ID(
        $casesAtRisk: cases_case_bool_exp!
        $total: cases_case_bool_exp!
        $eventId: [String!]!
    ) {
        cte_event(where: { eventId: { _in: $eventId } }) {
            deviceLocations_aggregate {
                aggregate {
                    count
                }
                nodes {
                    site_detail
                    site
                    place_id
                    casesAtRisk: currentCases_aggregate(where: $casesAtRisk) {
                        aggregate {
                            count
                        }
                    }
                    total: currentCases_aggregate(where: $total) {
                        aggregate {
                            count
                        }
                    }
                }
            }
        }
    }
`

export const GET_CASES_AND_COUNT_BY_SITE_ID = gql`
    query GET_CASES_AND_COUNT_BY_SITE_ID(
        $where: cases_case_bool_exp!
        $offset: Int!
        $limit: Int!
    ) {
        cases_case_aggregate(
            where: $where
            order_by: { expiryTimestamp: asc }
            offset: $offset
            limit: $limit
        ) {
            nodes {
                serialNumber
                shelfLifeRemaining
            }
        }
        cases_count: cases_case_aggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`

export const GET_GTIN_DATA_FOR_SKU = gql`
    query GET_GTIN_DATA_FOR_SKU {
        products_product(order_by: { isSelectedByDefault: desc_nulls_last }) {
            gtin
            label
            caseValue
            description
        }
    }
`

export const GET_PRODUCTS = gql`
    query GET_PRODUCTS {
        products_product(order_by: { isSelectedByDefault: desc_nulls_last }) {
            id
            label
            shelfLife
            gtin
            description
            caseValue
            averageShelfLife
            assets
            casesPerPalletCount
        }
    }
`

export const UPDATE_PRODUCT = gql`
    mutation UPDATE_PRODUCT($id: String!, $_set: products_product_set_input!) {
        update_products_product_by_pk(pk_columns: { id: $id }, _set: $_set) {
            id
            label
            shelfLife
            gtin
            description
            caseValue
            averageShelfLife
            casesPerPalletCount
        }
    }
`
export const UPDATE_CASE = gql`
    mutation UPDATE_CASE($id: String!, $_set: cases_case_set_input!) {
        update_cases_case_by_pk(pk_columns: { id: $id }, _set: $_set) {
            isActive
        }
    }
`
export const GET_EXPIRED_CASES_AT_CTES = gql`
    query GET_EXPIRED_CASES($gtin: [String!]!) {
        cases_case_aggregate(
            where: {
                gtin: { _in: $gtin }
                isCaseActiveAfterExpired: { _eq: true }
            }
        ) {
            aggregate {
                count
            }
            nodes {
                site
                serialNumber
                currentCteId
                current_place_id
                updated_at
                pingsAtLocation
                created_at
            }
        }
    }
`
export const GET_LOCATION_NAME_BY_SITE_ID = gql`
    query GET_LOCATION_NAME_BY_SITE_ID {
        locations_site {
            displayName
            site_code
        }
    }
`
export const GET_CASES_BY_TIME_RANGE = gql`
    query GET_CASES_BY_TIME_RANGE($_gte: timestamptz!, $_lte: timestamptz!) {
        cte_event {
            label
            eventId
            pastCases_aggregate(
                distinct_on: [caseId]
                where: { lastKnownLocationTime: { _gte: $_gte, _lte: $_lte } }
            ) {
                aggregate {
                    count
                }
            }
        }
    }
`
export const GET_NOTIFICATION_COUNTER_AND_CONTENT = gql`
    subscription GET_NOTIFICATION_COUNTER_AND_CONTENT(
        $where: notifications_notification_bool_exp!
    ) {
        notifications_notification_aggregate(where: $where) {
            aggregate {
                count
            }
            nodes {
                content
            }
        }
    }
`
