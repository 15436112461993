export const getFirstCharacterUpperCase = (str) => {
    let string = str ? str.toString() : ''
    let textToDisplay =
        string.charAt(0).toUpperCase() + string.substring(1, string.length)
    return textToDisplay
}

// Convert a number to a string with commas
export const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}