import React, { useState } from 'react'

import { useHistory } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'

import Sidebar from './sidebar'
import CollapsedSidebar from './collapsedSidebar'
import Header from './header/header'
import { screenIs } from '../ScreenSize'

const publicViewRoutes = ['/login', '/signup']
const Navigator = (props) => {
    return (
        <React.Fragment>
            <>
                {props.sidebarCollapsed ? (
                    <div
                        className="hidden lg:flex "
                        style={{
                            width: `${screenIs("lg") ? '5%' :'5%' }`,
                            // background: '#2F3B52',
                            background: '#332e45',
                            // height: windowHeight,
                        }}
                    >
                        <CollapsedSidebar />
                    </div>
                ) : (
                    <div className="md:w-full lg:w-2/12 mobile-sidebar">
                        <Sidebar toggleSidebar={props.toggleSidebar} />
                    </div>
                )}
            </>
        </React.Fragment>
    )
}

export default Navigator
