import React, {useEffect} from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Pagenotfound from 'views/Pagenotfound'
import DashboardPage from 'views/Dashboard'
import RecordsPage from 'views/Records'
import ProductPage from 'views/Products'
import CaseHistoryPage from 'views/CaseHistory';
import ExpiredCasesPage from 'views/ExpiredCases';
import CasesTimeRangePage from 'views/CasesTimerange';

import routes from 'routes';
import { withAuthenticationRequired } from '@auth0/auth0-react';

const PrivateRoute = ({component: Component, ...rest}) => (
    // <Route 
    //     exact
    //     component={
    //         (component , {
    //         onRedirecting: () => <div>Loading...</div>
    //     })}
    //     {...args}
    // />
    <Route exact {...rest} render={(props) => (
        <Component {...props} />
    )} />
)

const SecretRoute = ({ component: Component, ...rest }) => {
   
   return (
        <Route exact {...rest} render={(props) => (
            <Component {...props} />
        )} />
      );
} 

const AppRouter = () => (
    <div>
        <Switch>
            <PrivateRoute
                exact
                path={'/'}
                component={DashboardPage}
            />
             <PrivateRoute
                exact
                path={routes.products}
                component={ProductPage}
            />
            <PrivateRoute
                exact
                path={routes.records}
                component={RecordsPage}
            />
             <PrivateRoute
                exact
                path={routes.caseHistory}
                component={CaseHistoryPage}
            />
             <PrivateRoute
                exact
                path={routes.expiredCases}
                component={ExpiredCasesPage}
            />
             <PrivateRoute
                exact
                path={routes.casesTimeRange}
                component={CasesTimeRangePage}
            />
            <PrivateRoute component={Pagenotfound} />
        </Switch>
    </div>
);

export default AppRouter;