import React from 'react'
import { CSSTransition } from 'react-transition-group'

class OnMountTransition extends React.PureComponent {
    render() {
        if (typeof window != 'undefined') {
            return (
                <CSSTransition
                    in={true}
                    classNames="onMount"
                    appear={true}
                    unmountOnExit
                    timeout={400}
                >
                    {this.props.children}
                </CSSTransition>
            )
        } else {
            return this.props.children
        }
    }
}

export default OnMountTransition
