import React, { useState, useRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSubscription } from '@apollo/client'
import dayjs from 'dayjs'
import moment from 'moment-timezone'

import Spinner from 'components/common/spinner'
import { GET_NOTIFICATION_COUNTER_AND_CONTENT } from 'schema/queries/index'
import NotificationBell from 'assets/bell_icon.svg'
import MenuPopup from 'components/common/menuPopup'
import DropdownArrow from 'assets/dropdown_arrow.png'
import './header.css'

const Notifications = ({ userDetails }) => {
    const [prevNotifications, setPrevNotifications] = useState(0)
    const [seenNotification, setSeenNotification] = useState(false)
    const [selectedDate, setSelectedDate] = useState('')

    const userSetsWhere =
        selectedDate !== '' ? { created_at: { _gt: selectedDate } } : {}

    const {
        loading: notificationLogsLoading,
        data: notificationLogsData,
        error: notificationLogsError,
    } = useSubscription(GET_NOTIFICATION_COUNTER_AND_CONTENT, {
        variables: {
            where: userSetsWhere,
            // where:{
            //     created_at: { _gt: selectedDate }
            // }
        },
    })

    useEffect(() => {
        const lastNotificationSeen = localStorage.getItem(
            'lastSeenNotificationTime'
        )
        if (lastNotificationSeen) {
            setSelectedDate(moment(lastNotificationSeen).format())
        }
    }, [seenNotification])

    if (notificationLogsError) {
        console.error('notificationLogsError', notificationLogsError)
        return (
            <div className="text-white cursor-not-allowed">
                <img alt="bell" src={NotificationBell} className="w-4 h-4" />
            </div>
        )
    }

    const getNotificationCounter = () => {
        let counter = 0
        let showCounter = 0
        let lastSeenNotificationFound = false

        if (
            notificationLogsData?.notifications_notification_aggregate.aggregate
        ) {
            showCounter =
                notificationLogsData.notifications_notification_aggregate
                    .aggregate.count
        }
        if (
            counter > 0 &&
            showCounter <= 0 &&
            lastSeenNotificationFound === false
        ) {
            showCounter = counter
        }

        return showCounter
    }

    let notificationCounter = getNotificationCounter()

    const setNewDateAndTime = () => {
        localStorage.setItem(
            'lastSeenNotificationTime',
            moment(new Date()).format()
        )
        setSeenNotification(!seenNotification)
    }

    return (
        <>
            <div className="relative inline-flex mr-2 cursor-pointer ">
                <MenuPopup
                    menuContent={
                        <MenuContent
                            notificationLogsLoading={notificationLogsLoading}
                            notificationLogsData={notificationLogsData}
                            setNewDateAndTime={setNewDateAndTime}
                            counter={notificationCounter}
                        />
                    }
                    className="new-sidebar-bg"
                    style={'max-height: 100px'}
                >
                    <div>
                        <img
                            alt="bell"
                            src={NotificationBell}
                            className="w-5 h-5"
                        />
                        {/* {notificationCounter > 0 ? ( */}
                        <div
                            className="absolute bottom-2 left-2 w-4 h-4 text-xs 
                            flex items-center justify-center
                             bg-red-500 rounded-full text-white "
                        >
                            {/* {userDetails?.lastSeenNotificationId
                                    ? getNotificationCounter()
                                    : null} */}
                            {getNotificationCounter()}
                        </div>
                        {/* ) : null} */}
                    </div>
                </MenuPopup>
            </div>
        </>
    )
}

const MenuContent = ({
    notificationLogsData,
    notificationLogsLoading,
    setNewDateAndTime = () => {},
    counter,
}) => {
    const history = useHistory()
    if (notificationLogsLoading) {
        return <Spinner />
    }

    return (
        <>
            <div
                className="new-sidebar-bg w-full"
                style={{ overflow: 'auto', maxHeight: '200px' }}
            >
                {counter !== 0 ? (
                    <div className="flex justify-end text-red-500">
                        <button onClick={() => setNewDateAndTime()}>
                            Clear
                        </button>
                    </div>
                ) : null}
                {notificationLogsData.notifications_notification_aggregate.nodes
                    .length > 0 ? (
                    notificationLogsData.notifications_notification_aggregate.nodes.map(
                        (item, index) => (
                            <React.Fragment key={index}>
                                <div
                                    className="flex border-b border-neutral-gray-500 pb-2 pt-2 cursor-pointer "
                                    // onClick={() => {
                                    //     history.push(item.content.onAction)
                                    // }}
                                >
                                    <div className="pt-1">
                                        <img
                                            className="w-5 h-5 my-auto"
                                            src={
                                                item.content?.logo ||
                                                'https://orna-wembleypartners.s3.us-east-2.amazonaws.com/new+icon/icon-info.svg'
                                            }
                                            alt="notification-logo"
                                        />
                                    </div>
                                    <div className="w-full text-white pl-4">
                                        <div>{item.content?.content}</div>
                                        {/* <div className=" text-neutral-gray-75  font-bold">
                                        {dayjs(item.created_at * 1000).format(
                                            'ddd, MMM D, YYYY h:mm A'
                                        )}
                                    </div> */}
                                    </div>
                                </div>
                            </React.Fragment>
                        )
                    )
                ) : (
                    <div>No notifications recorded</div>
                )}
            </div>
        </>
    )
}

export default Notifications
