// FOr side panal

// GET_LOCATION_NAME_BY_SITE_ID
export const siteDataModel = {
    "locations_site": [
        {
            "displayName": "6296",
            "site_code": "0195500234601",
            "__typename": "locations_site"
        },
        {
            "displayName": "5323",
            "site_code": "0195500235004",
            "__typename": "locations_site"
        },
        {
            "displayName": "17998",
            "site_code": "0195500239293",
            "__typename": "locations_site"
        },
        {
            "displayName": "23584",
            "site_code": "0195500329765",
            "__typename": "locations_site"
        },
        {
            "displayName": "25790",
            "site_code": "0195500338286",
            "__typename": "locations_site"
        },
        {
            "displayName": "36489",
            "site_code": "0195500783680",
            "__typename": "locations_site"
        },
        {
            "displayName": "36605",
            "site_code": "0195500788197",
            "__typename": "locations_site"
        },
        {
            "displayName": "2893",
            "site_code": "0195500234304",
            "__typename": "locations_site"
        },
        {
            "displayName": "10751",
            "site_code": "0195500237299",
            "__typename": "locations_site"
        },
        {
            "displayName": "6660",
            "site_code": "0195500237404",
            "__typename": "locations_site"
        },
        {
            "displayName": "Opelika",
            "site_code": "0708112000016",
            "__typename": "locations_site"
        },
        {
            "displayName": "Opelika",
            "site_code": "0708112000498",
            "__typename": "locations_site"
        },
        {
            "displayName": "Others",
            "site_code": "Other Restaurants",
            "__typename": "locations_site"
        },
        {
            "displayName": "Others",
            "site_code": "Other Distributor",
            "__typename": "locations_site"
        },
        {
            "displayName": "MB - Orlando",
            "site_code": "0888632000038",
            "__typename": "locations_site"
        }
    ]
}

//for GET_CASE_BY_CASEID
export const singleCaseModel = {
    "cases_case": []
}

// for GET_CASES_AND_COUNT_BY_SITE_ID
export const casesDataModel = {

    "cases_case_aggregate": {
        "nodes": [],
        "__typename": "cases_case_aggregate"
    },
    "cases_count": {
        "aggregate": {
            "count": 0,
            "__typename": "cases_case_aggregate_fields"
        },
        "__typename": "cases_case_aggregate"
    }

}

// for GET_ALL_COUNT_PER_CTE 
export const dataModel = {
    "ctes": [
        {
            "label": "Production Line",
            "eventId": "1",
            "containsCasesAtRisk": true,
            "deviceLocations_aggregate": {
                "aggregate": {
                    "count": 5,
                    "__typename": "locations_deviceLocation_aggregate_fields"
                },
                "nodes": [
                    {
                        "site_detail": "Packing Line 01",
                        "site": "0708112000498",
                        "place_id": "a7a2a55b-6e39-4d64-8f3b-b0d2f2323111",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 378,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Packing Line 02",
                        "site": "0708112000498",
                        "place_id": "736cac18-6248-4183-86fb-658c1a07cdf9",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 183,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Packing Line 03",
                        "site": "0708112000498",
                        "place_id": "31b2703a-b3db-4096-bd70-da21778c490a",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 654,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Packing Line 04",
                        "site": "0708112000498",
                        "place_id": "516eadca-e7bd-4cb2-b8a4-5056887ffdf5",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 138,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Packing Line 05",
                        "site": "0708112000498",
                        "place_id": "6b7fde3e-82d8-443b-b384-d71341e80820",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 827,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    }
                ],
                "__typename": "locations_deviceLocation_aggregate"
            },
            "__typename": "cte_event"
        },
        {
            "label": "Manufacturing",
            "eventId": "1b",
            "containsCasesAtRisk": true,
            "deviceLocations_aggregate": {
                "aggregate": {
                    "count": 5,
                    "__typename": "locations_deviceLocation_aggregate_fields"
                },
                "nodes": [
                    {
                        "site_detail": "Palletizer Automated",
                        "site": "0708112000498",
                        "place_id": "b0e95aa5-654a-4e47-97e6-7d8b563f7597",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Palletizer Automated",
                        "site": "0708112000498",
                        "place_id": "1e0f611f-acb6-472d-b467-54377cd502e3",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 16479,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Palletizer Automated",
                        "site": "0708112000498",
                        "place_id": "982c33a6-2d56-4f38-803a-3af8f12f8a5f",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 19123,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Palletizer Automated",
                        "site": "0708112000498",
                        "place_id": "0dd5c22b-e840-42be-858f-c358f09a80d6",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 11807  ,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Palletizer Automated",
                        "site": "0708112000498",
                        "place_id": "e48bedd3-b3cf-4f72-acb5-0525f642314a",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    }
                ],
                "__typename": "locations_deviceLocation_aggregate"
            },
            "__typename": "cte_event"
        },
        {
            "label": "In-Transit (Distribution)",
            "eventId": "2",
            "containsCasesAtRisk": false,
            "deviceLocations_aggregate": {
                "aggregate": {
                    "count": 2,
                    "__typename": "locations_deviceLocation_aggregate_fields"
                },
                "nodes": [
                    {
                        "site_detail": "Outbound Door 13",
                        "site": "0708112000498",
                        "place_id": "f5356398-3b64-4834-bd34-6ef0aef53931",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 7112,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Outbound Door 14",
                        "site": "0708112000498",
                        "place_id": "fe5b2ae7-07f5-449f-9559-9e5902bfbdcb",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    }
                ],
                "__typename": "locations_deviceLocation_aggregate"
            },
            "__typename": "cte_event"
        },
        {
            "label": "Distribution",
            "eventId": "3",
            "containsCasesAtRisk": false,
            "deviceLocations_aggregate": {
                "aggregate": {
                    "count": 28,
                    "__typename": "locations_deviceLocation_aggregate_fields"
                },
                "nodes": [
                    {
                        "site_detail": "Other Distributor",
                        "site": "Other Distributor",
                        "place_id": "24-hour-update-cte-2-simulation",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 48371,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dock Door 05",
                        "site": "0888632000038",
                        "place_id": "128cdfa4-478c-462e-89a6-c47031cfb0ee",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 1300,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dock Door 24",
                        "site": "0888632000038",
                        "place_id": "2b261971-3b9d-4b62-af2a-4022f9f757de",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dock Door 03",
                        "site": "0888632000038",
                        "place_id": "f7d888ff-75b7-4ac0-9cb0-ee4e03788765",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dock Door 08",
                        "site": "0888632000038",
                        "place_id": "d5c65118-26f4-4678-80dd-a6b3f91d0947",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dock Door 14",
                        "site": "0888632000038",
                        "place_id": "7eee3e92-86ff-44b7-8ac1-2d8cfba8a6a1",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Rail Dock Door 01",
                        "site": "0888632000038",
                        "place_id": "090a20ba-e74c-42f1-94cd-1b80d1bbed11",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Rail Dock Door 03",
                        "site": "0888632000038",
                        "place_id": "66c1b089-42ca-4592-a0b9-a50cd5279aa3",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Rail Dock Door 02",
                        "site": "0888632000038",
                        "place_id": "81ff6481-2538-4ee3-8a6f-f461b83cb32f",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dock Door 01",
                        "site": "0888632000038",
                        "place_id": "b9afbd68-04e1-4001-a682-6a15d6910c95",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dock Door 10",
                        "site": "0888632000038",
                        "place_id": "722faa66-bedb-442d-9be4-8675d998ea45",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dock Door 19",
                        "site": "0888632000038",
                        "place_id": "714a65e4-de42-4753-b4b7-4f8ab955cf98",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dock Door 09",
                        "site": "0888632000038",
                        "place_id": "4f8c123f-7912-4f58-81d1-8f7132697799",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dock Door 22",
                        "site": "0888632000038",
                        "place_id": "29955979-f768-4863-ba39-ad4ededf8b0f",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dock Door 17",
                        "site": "0888632000038",
                        "place_id": "84c5a609-bdd9-4fd4-8f6b-767a733a2aa8",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dock Door 06",
                        "site": "0888632000038",
                        "place_id": "58c5a92f-011d-4c13-93c2-46247a111bfd",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dock Door 02",
                        "site": "0888632000038",
                        "place_id": "f7f33298-908d-41d8-aa2f-47ea1fcbe339",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dock Door 15",
                        "site": "0888632000038",
                        "place_id": "0f871869-06af-4574-9d3d-4741de5f646c",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dock Door 13",
                        "site": "0888632000038",
                        "place_id": "6852f09d-3084-4a16-b6d1-185b74451324",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dock Door 21",
                        "site": "0888632000038",
                        "place_id": "73de59da-87e3-4216-8d62-40bfb12b45b6",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dock Door 23",
                        "site": "0888632000038",
                        "place_id": "c08c458c-7c2d-4afd-b42c-15c7c0e3755f",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dock Door 18",
                        "site": "0888632000038",
                        "place_id": "5c8161b3-c2fa-4ba7-bf55-62397e14680c",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dock Door 16",
                        "site": "0888632000038",
                        "place_id": "cc7af324-dcca-4b6e-a12b-39380d51f86a",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dock Door 07",
                        "site": "0888632000038",
                        "place_id": "ebee088d-61ee-4023-bce2-dc86a8595731",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dock Door 04",
                        "site": "0888632000038",
                        "place_id": "256db74c-7529-406d-bea0-5e009cbbf92c",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dock Door 12",
                        "site": "0888632000038",
                        "place_id": "0ebb22b5-1fd9-4420-9c9c-4fed09c513eb",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dock Door 11",
                        "site": "0888632000038",
                        "place_id": "7c723c2e-340d-4057-8679-e9a7dcc1b7ae",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dock Door 20",
                        "site": "0888632000038",
                        "place_id": "04820c39-f13f-43f5-9b90-8c3854d78209",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    }
                ],
                "__typename": "locations_deviceLocation_aggregate"
            },
            "__typename": "cte_event"
        },
        {
            "label": "In Transit (Restaurant)",
            "eventId": "4",
            "containsCasesAtRisk": false,
            "deviceLocations_aggregate": {
                "aggregate": {
                    "count": 27,
                    "__typename": "locations_deviceLocation_aggregate_fields"
                },
                "nodes": [
                    {
                        "site_detail": "Dock Door 05",
                        "site": "0888632000038",
                        "place_id": "ca4bf579-d220-418f-a5bf-8d9f9d4cb468",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 5607,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dock Door 24",
                        "site": "0888632000038",
                        "place_id": "0932a336-34d3-4b70-bf9c-06682225a482",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dock Door 03",
                        "site": "0888632000038",
                        "place_id": "acdf8bea-bd9f-4699-a164-29478f8fe4f6",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dock Door 08",
                        "site": "0888632000038",
                        "place_id": "c736aa91-9acd-4f79-90d4-9a86e6745adc",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dock Door 14",
                        "site": "0888632000038",
                        "place_id": "0f2e5dbc-ff21-49d8-84be-c65a6c42a448",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Rail Dock Door 01",
                        "site": "0888632000038",
                        "place_id": "3130369c-dc39-4882-92d5-0b995c4dee7f",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Rail Dock Door 03",
                        "site": "0888632000038",
                        "place_id": "bdce6cb9-2b2f-460c-a2d3-927b8e2d0c54",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Rail Dock Door 02",
                        "site": "0888632000038",
                        "place_id": "13ec75fb-e5b3-487b-974e-77263dc475aa",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dock Door 01",
                        "site": "0888632000038",
                        "place_id": "631f53ad-cf27-40c1-909f-2a33aae6d2b7",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dock Door 10",
                        "site": "0888632000038",
                        "place_id": "6479f080-9fb3-4fb3-b479-d2cff6253219",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dock Door 19",
                        "site": "0888632000038",
                        "place_id": "b3ae0926-b14b-4b48-a784-c06875103bec",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dock Door 09",
                        "site": "0888632000038",
                        "place_id": "8cd76e9d-5215-457d-935a-35c81f83980b",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dock Door 22",
                        "site": "0888632000038",
                        "place_id": "a6bb724d-d1ee-41cb-95a5-16b81aaf345a",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dock Door 17",
                        "site": "0888632000038",
                        "place_id": "cad23a87-2a25-45ab-867f-198dad9f61e1",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dock Door 06",
                        "site": "0888632000038",
                        "place_id": "37758c49-7851-4a93-ba5d-5b7ecc76a474",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dock Door 02",
                        "site": "0888632000038",
                        "place_id": "7b84b722-c25b-4d95-8862-0885f6dd3bc4",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dock Door 15",
                        "site": "0888632000038",
                        "place_id": "f2e6cacf-376a-43be-a573-0cd8e8a929c4",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dock Door 13",
                        "site": "0888632000038",
                        "place_id": "a68afebd-323f-4bf3-a16e-3341e09ac33e",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dock Door 21",
                        "site": "0888632000038",
                        "place_id": "65e240d0-e235-4ecb-bac1-51e735fd0002",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dock Door 23",
                        "site": "0888632000038",
                        "place_id": "4bf03e51-9504-446a-8a37-cbbbbc1b5ed1",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dock Door 18",
                        "site": "0888632000038",
                        "place_id": "31434542-ce38-4c4f-bf59-5be72abf9b79",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dock Door 16",
                        "site": "0888632000038",
                        "place_id": "3480c89e-317c-4226-be78-0dbb4af38370",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dock Door 07",
                        "site": "0888632000038",
                        "place_id": "0cb2ede0-5988-420f-b197-011257f0520a",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dock Door 04",
                        "site": "0888632000038",
                        "place_id": "58c0ad05-d34f-4134-9002-02a16ace753b",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dock Door 12",
                        "site": "0888632000038",
                        "place_id": "f9f24ce2-7adb-44b0-9e03-633095a22bc8",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dock Door 11",
                        "site": "0888632000038",
                        "place_id": "db3fde94-c88e-4f29-b5d1-761f5667fbcf",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dock Door 20",
                        "site": "0888632000038",
                        "place_id": "8ca586d8-fb55-405a-a91a-79de64dc9337",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    }
                ],
                "__typename": "locations_deviceLocation_aggregate"
            },
            "__typename": "cte_event"
        },
        {
            "label": "Restaurant",
            "eventId": "5",
            "containsCasesAtRisk": false,
            "deviceLocations_aggregate": {
                "aggregate": {
                    "count": 7,
                    "__typename": "locations_deviceLocation_aggregate_fields"
                },
                "nodes": [
                    {
                        "site_detail": "Other Restaurants",
                        "site": "Other Restaurants",
                        "place_id": "24-hour-update-cte-4-simulation",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 222,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 19,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Outside 05",
                        "site": "0195500237299",
                        "place_id": "3c8b4f9d-57b9-4f12-9f07-0f89723d69c2",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 70,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 10,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Outside 02",
                        "site": "0195500235004",
                        "place_id": "689d8e1f-50fd-4bc1-b227-4a2962a2f634",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 90,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 17,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Receiving 04",
                        "site": "0195500237299",
                        "place_id": "c50866ae-e36a-4c3d-b117-36123b03ae6c",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Outside 01",
                        "site": "0195500234601",
                        "place_id": "80799478-f40a-4069-998a-8b9cb596d909",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 95,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 15,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Outside 05",
                        "site": "0195500237404",
                        "place_id": "31e517c6-6158-4db7-a5c0-c1f95e67ac07",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 80,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 18,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Outside 08",
                        "site": "0195500234304",
                        "place_id": "8cd83bd8-20e9-48ae-bb81-cd098637f0ac",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 85,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 7,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "DryGoods",
                        "site": "0195500239293",
                        "place_id": "e8512500-27c9-4383-b05c-77a66129f004",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 67,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 15,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "DryGoods",
                        "site": "0195500329765",
                        "place_id": "0ff2a76b-e1a8-4c80-8165-678f414f68a3",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 10,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 16,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Main",
                        "site": "0195500338286",
                        "place_id": "2fc06638-0df6-4334-8f58-3fae72e1e769",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 2,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 11,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "ChillerDryGoods",
                        "site": "0195500783680",
                        "place_id": "e878b17e-fb73-4f28-9c7d-030237a4a54d",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 8,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Freezer",
                        "site": "0195500788197",
                        "place_id": "f7f84a20-6c73-4438-ab08-b558a8750fa7",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 20,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 19,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },

                ],
                "__typename": "locations_deviceLocation_aggregate"
            },
            "__typename": "cte_event"
        },
        {
            "label": "Restaurant Storage",
            "eventId": "6",
            "containsCasesAtRisk": false,
            "deviceLocations_aggregate": {
                "aggregate": {
                    "count": 105,
                    "__typename": "locations_deviceLocation_aggregate_fields"
                },
                "nodes": [
                    {
                        "site_detail": null,
                        "site": "0195500239293",
                        "place_id": "da97e450-ab4c-4c9b-83a3-5f90312016be",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 20,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "DryGoods",
                        "site": "0195500239293",
                        "place_id": "e8512500-27c9-4383-b05c-77a66129f004",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "DryGoods",
                        "site": "0195500329765",
                        "place_id": "0ff2a76b-e1a8-4c80-8165-678f414f68a3",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 75,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 10,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Main",
                        "site": "0195500338286",
                        "place_id": "2fc06638-0df6-4334-8f58-3fae72e1e769",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 80,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 17,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": null,
                        "site": "0195500239293",
                        "place_id": "9a69b02a-358e-4ee8-a2ee-3ed582a6c608",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Chiller Freezer",
                        "site": "0195500329765",
                        "place_id": "bef49f98-f1dd-4205-82e8-659ef16622eb",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "ChillerDryGoods",
                        "site": "0195500783680",
                        "place_id": "e878b17e-fb73-4f28-9c7d-030237a4a54d",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 60,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 13,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Freezer",
                        "site": "0195500788197",
                        "place_id": "f7f84a20-6c73-4438-ab08-b558a8750fa7",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 75,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 9,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Chiller",
                        "site": "0195500788197",
                        "place_id": "673f39a8-2b1e-488a-bd8c-1d04c33b1bb1",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 8,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Cooler 04",
                        "site": "0195500237404",
                        "place_id": "bc3066e3-6ec1-4cef-876a-4eeef6871daf",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Freezer 01",
                        "site": "0195500234304",
                        "place_id": "f869093f-1245-4ef8-ba96-c22719dfbbdd",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Cooler 02",
                        "site": "0195500234304",
                        "place_id": "12364aca-9ce0-4712-95b3-4f29f01778c3",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Freezer 03",
                        "site": "0195500234304",
                        "place_id": "a328a76c-22bb-45f6-9934-62951405aeb2",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": null,
                        "site": "0195500239293",
                        "place_id": "76faac8d-e8f3-44a7-8e93-e27984c9106a",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Cooler 04",
                        "site": "0195500234304",
                        "place_id": "1fa8066a-bfad-4630-871b-045b3ff56c8b",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 1,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Freezer 01",
                        "site": "0195500235004",
                        "place_id": "d59eb979-c6df-4a3b-a718-f9da647cc73c",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Cooler 02",
                        "site": "0195500235004",
                        "place_id": "93f285f7-ae64-48a0-8217-afd5efc56b96",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Freezer 03",
                        "site": "0195500235004",
                        "place_id": "a33384e8-7a4d-4dc4-8891-3a89da801d5f",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Cooler 04",
                        "site": "0195500235004",
                        "place_id": "1bed8e14-077d-4c04-b964-8bd61b2965e0",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Freezer 01",
                        "site": "0195500234601",
                        "place_id": "aa0ec0a2-7b33-40f2-9dc7-fd3bca64d0f9",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Cooler 02",
                        "site": "0195500234601",
                        "place_id": "f229e6db-97ee-4f49-94e5-095d7c596065",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Freezer 03",
                        "site": "0195500234601",
                        "place_id": "f7603125-f236-4551-8428-f7f1e4d290e6",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Cooler 04",
                        "site": "0195500234601",
                        "place_id": "632c5bce-fb2e-439e-af5d-0044b1d3d397",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Chiller Freezer",
                        "site": "0195500329765",
                        "place_id": "a4558342-0e48-4f8e-adc7-eecaa125d18b",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "ChillerDryGoods",
                        "site": "0195500783680",
                        "place_id": "59242c92-62ee-40cc-be73-bdcf185e87cc",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": null,
                        "site": "0195500239293",
                        "place_id": "11917ae0-c23a-438d-8dd6-e69ecf778e22",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Freezer 01",
                        "site": "0195500237299",
                        "place_id": "d95107a9-ec55-4deb-857c-e117133a81c9",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Cooler 02",
                        "site": "0195500237299",
                        "place_id": "a221ceed-84f0-4461-9a2c-9302560d9f53",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Freezer 03",
                        "site": "0195500237299",
                        "place_id": "4c03ea75-e2aa-4d9a-bfce-752d5ffcc491",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Cooler 04",
                        "site": "0195500237299",
                        "place_id": "816be055-c6c5-48f9-92c2-0108720b8f22",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Freezer",
                        "site": "0195500783680",
                        "place_id": "38826348-480a-49ec-af2f-f3c506267d9d",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Chiller Freezer",
                        "site": "0195500329765",
                        "place_id": "ba6f4955-9f01-431b-8ff7-c6e11975eaf3",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "ChillerDryGoods",
                        "site": "0195500783680",
                        "place_id": "ad25ea7b-322a-42ac-addf-1f519c630aaf",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Chiller Freezer",
                        "site": "0195500329765",
                        "place_id": "aba08f43-a6b1-4126-8c3a-eab41b985bf4",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "ChillerDryGoods",
                        "site": "0195500783680",
                        "place_id": "7a9d0270-2411-462d-b1ff-23f3a9dac9cd",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "ChillerDryGoods",
                        "site": "0195500783680",
                        "place_id": "c6821395-da2b-439a-90e5-624519168528",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Bun Thaw 01",
                        "site": "0195500237299",
                        "place_id": "e4c8eab8-4d73-470b-a4f1-a7c7bf1399fd",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Chiller Freezer",
                        "site": "0195500329765",
                        "place_id": "0eb81a86-5749-42e4-a2a5-69f1b2708017",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "ChillerDryGoods",
                        "site": "0195500783680",
                        "place_id": "5a4872f7-3d6b-440a-bee3-1a1a59116b1f",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Freezer",
                        "site": "0195500788197",
                        "place_id": "09be8ceb-e2a1-4272-a0e7-b8ddbf6cbb58",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 12,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Main",
                        "site": "0195500338286",
                        "place_id": "e08df4ad-1680-4781-9f55-9b0f5cf47c0e",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Freezer",
                        "site": "0195500783680",
                        "place_id": "c1be1611-01a5-44b3-8a20-64682cdffe22",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Freezer",
                        "site": "0195500788197",
                        "place_id": "ef9da1e2-e205-4cfa-b182-493f0fd9cf10",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 14,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dry Goods 07",
                        "site": "0195500235004",
                        "place_id": "4074699c-ef96-4da8-8bdc-7dad3286a986",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Bun Thaw 01",
                        "site": "0195500235004",
                        "place_id": "f82b767d-b236-4510-af1e-3ca48db4b9b3",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Bun Thaw 02",
                        "site": "0195500235004",
                        "place_id": "39db823c-b890-4457-9661-3efe5737350a",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Bun Thaw 03",
                        "site": "0195500235004",
                        "place_id": "f4d4072f-cd29-46cc-8a3b-eeb3462ecbaa",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dry Goods 02",
                        "site": "0195500234601",
                        "place_id": "60ede808-726b-43dc-993c-2f6218cc7c63",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "DryGoods",
                        "site": "0195500329765",
                        "place_id": "053f57e1-efa5-49fa-8b22-521957f68e4f",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Bun Thaw 02",
                        "site": "0195500237299",
                        "place_id": "8de969ed-5c63-4516-a727-061caaa92544",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Bun Thaw 03",
                        "site": "0195500237299",
                        "place_id": "1a2e4b37-d133-4bba-b3df-b2a6b4fb3782",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Outside 05",
                        "site": "0195500237299",
                        "place_id": "74b1321e-5ca1-4080-8ac8-c86dda262dba",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "DryGoods",
                        "site": "0195500239293",
                        "place_id": "61aaaf6f-685b-4b19-98e2-64902262c530",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 2,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 3,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Main",
                        "site": "0195500338286",
                        "place_id": "658406e2-c464-46f1-bbc3-3e9c8df77734",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "DryGoods",
                        "site": "0195500239293",
                        "place_id": "d845370c-cc93-4e44-9ce9-f56ccd5796a4",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 3,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 5,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Main",
                        "site": "0195500338286",
                        "place_id": "1f9d4fde-7284-453e-a8e6-549334900dd7",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Freezer",
                        "site": "0195500783680",
                        "place_id": "1dad5dce-0b74-4c6f-ab51-697b598149ca",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dry Goods 05",
                        "site": "0195500234601",
                        "place_id": "3f8e5300-45ac-4dde-99a1-21a511af6234",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dry Goods 06",
                        "site": "0195500234601",
                        "place_id": "22e3c5e5-b014-485b-be7d-b729f39a6d82",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dry Goods 07",
                        "site": "0195500234601",
                        "place_id": "0166e5cc-d516-49fd-b8c0-cd0efa1caa4f",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Bun Thaw 08",
                        "site": "0195500234601",
                        "place_id": "e31b1311-03de-467e-a0c2-686c4f840747",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Bun Thaw 01",
                        "site": "0195500234601",
                        "place_id": "fa34dbcd-865b-44b8-9563-9fc35a7b95f5",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Bun Thaw 02",
                        "site": "0195500234601",
                        "place_id": "736c258e-d9f3-474f-9702-2ac3bdfe6bff",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Bun Thaw 03",
                        "site": "0195500234601",
                        "place_id": "85c27406-07b4-435f-bccc-6c127b337a40",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Bun Thaw 01",
                        "site": "0195500237404",
                        "place_id": "c46ec67d-c315-4e7c-9661-ac879fd6fc3c",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Bun Thaw 02",
                        "site": "0195500237404",
                        "place_id": "2bae36fc-345a-4838-a181-a9bebe964b7e",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dry Goods 03",
                        "site": "0195500237404",
                        "place_id": "7ce5fcea-20b7-42e7-861d-6d390dcc1115",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dry Goods 06",
                        "site": "0195500237404",
                        "place_id": "19bc7207-10ce-4718-8db4-5ec028c4c502",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dry Goods 07",
                        "site": "0195500237404",
                        "place_id": "8aa64dfa-cb2c-4647-b742-95eb90a32ce7",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dry Goods 03",
                        "site": "0195500234601",
                        "place_id": "6dec0009-82e4-4c2c-957e-872c38620002",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dry Goods 04",
                        "site": "0195500234601",
                        "place_id": "59a84cca-7031-4469-86ca-8a4a38232c38",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Freezer",
                        "site": "0195500783680",
                        "place_id": "41b73e50-ff9b-467f-aa08-9af0c4bae594",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Freezer",
                        "site": "0195500788197",
                        "place_id": "72e74a87-13d0-4f51-8228-0fcfe4ca4f98",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 16,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "DryGoods",
                        "site": "0195500329765",
                        "place_id": "b5bb7d36-89cf-4c1d-a6ba-f0e74d37db91",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Main",
                        "site": "0195500338286",
                        "place_id": "48873046-83ff-4a4b-a917-7e2b276fe340",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Outside 05",
                        "site": "0195500237404",
                        "place_id": "1cbbc06d-2b9b-468f-92de-1a4bcc4d6158",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Kitchen 06",
                        "site": "0195500237404",
                        "place_id": "dd1c55dc-5e71-42a6-844b-2fc874ecd01c",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dry Goods 01",
                        "site": "0195500237299",
                        "place_id": "3ec3622e-0e5f-48d9-8892-51007fcf9f51",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dry Goods 04",
                        "site": "0195500237404",
                        "place_id": "c4752758-1197-4df4-9d5f-724321982528",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dry Goods 02",
                        "site": "0195500237299",
                        "place_id": "87031f5e-06a6-4123-9ea7-037664ade76d",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dry Goods 03",
                        "site": "0195500237299",
                        "place_id": "095abd4b-6be7-49c1-a59a-df2e67251cf0",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dry Goods 04",
                        "site": "0195500237299",
                        "place_id": "4b62f043-84eb-48cb-8c1a-4a2f3a920f8a",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dry Goods 06",
                        "site": "0195500237299",
                        "place_id": "b54632a0-488d-4849-81df-2fb0cd9eb2c8",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "BT/Liq 01",
                        "site": "0195500234304",
                        "place_id": "73350ee8-e07c-4032-a447-4d582b9b63ed",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "BT/Liq 02",
                        "site": "0195500234304",
                        "place_id": "ed002178-e969-4fb4-a4ea-967e6cae3799",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "BT/Liq 03",
                        "site": "0195500234304",
                        "place_id": "c90f2ef3-02cb-4182-afab-799422d64382",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Bun Thaw 04",
                        "site": "0195500234304",
                        "place_id": "0284b0ae-e32e-4bf6-ab47-54648080a6fa",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dry Goods 01",
                        "site": "0195500234304",
                        "place_id": "63c705dd-ec34-467c-993c-18f3977db2d7",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Outside 02",
                        "site": "0195500234304",
                        "place_id": "f82579e6-8c24-4177-89d8-6de72fe966ad",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dry Goods 03",
                        "site": "0195500234304",
                        "place_id": "edaa1ee6-1aec-4d68-a864-07fe66f34e82",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dry Goods 04",
                        "site": "0195500234304",
                        "place_id": "1a067655-b57b-4567-b079-785d98c2a53f",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dry Goods 01",
                        "site": "0195500235004",
                        "place_id": "14b87eed-e3c0-483d-8cf7-f451146fed8d",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dry Goods 03",
                        "site": "0195500235004",
                        "place_id": "edde63f1-2b1a-4c96-b5aa-ad7ad1dc1c21",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Chiller Freezer",
                        "site": "0195500329765",
                        "place_id": "dc2ba42a-55db-46a2-9c40-dff3fdcc34e2",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dry Goods 04",
                        "site": "0195500235004",
                        "place_id": "ba809a3d-47c4-46b2-ab18-c81a568274a7",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Freezer 01",
                        "site": "0195500237404",
                        "place_id": "5e4de1b5-e339-4aa0-8b18-144d85314761",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Cooler 02",
                        "site": "0195500237404",
                        "place_id": "b17dd33a-5b05-4e8a-8912-6fa2b232d966",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Freezer 03",
                        "site": "0195500237404",
                        "place_id": "b9820a59-b9d5-4abe-8360-dcb26af78525",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "ChillerDryGoods",
                        "site": "0195500783680",
                        "place_id": "6f851f82-4f5d-4bce-8dad-afa4e9c3c868",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Chiller",
                        "site": "0195500788197",
                        "place_id": "76e53924-50f0-49af-8cef-bd5abfa4883b",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 18,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dry Goods 05",
                        "site": "0195500235004",
                        "place_id": "dfbab537-6fed-4a79-908d-24d32bb28fa3",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dry Goods 06",
                        "site": "0195500235004",
                        "place_id": "dae88edc-1f85-46e4-a850-09265562b27a",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "DryGoods",
                        "site": "0195500239293",
                        "place_id": "44116608-d47a-4854-8de3-0e379799d9b6",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Dry Goods 08",
                        "site": "0195500237404",
                        "place_id": "f9b997ac-e331-4b90-96c0-674ccb481cab",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    },
                    {
                        "site_detail": "Main",
                        "site": "0195500338286",
                        "place_id": "f73dd29e-23f4-4da5-9bae-13607eb8e942",
                        "currentCases_aggregate": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "today": {
                            "aggregate": {
                                "count": 0,
                                "__typename": "cases_case_aggregate_fields"
                            },
                            "__typename": "cases_case_aggregate"
                        },
                        "__typename": "locations_deviceLocation"
                    }
                ],
                "__typename": "locations_deviceLocation_aggregate"
            },
            "__typename": "cte_event"
        }
    ]
}

// END side panel



// For graph (GET_AVG_SHELF_LIFE)
export const avgShelfLifeDataModel = {
    "products_product": [
        {
            "averageShelfLife": 11.06,
            "caseValue": 44.91375,
            "casesByShelfLifeinDaysPerCte": [
                {
                    "count": 0,
                    "daysRemaining": 0,
                    "isCaseAtRisk": false,
                },
                {
                    "count":6500,
                    "daysRemaining": 1,
                    "isCaseAtRisk": true,
                },
                {
                    "count": 9876,
                    "daysRemaining": 2,
                    "isCaseAtRisk": true,
                },
                {
                    "count": 8345,
                    "daysRemaining": 3,
                    "isCaseAtRisk": true,
                },
               
                {
                    "count": 11329,
                    "daysRemaining": 5,
                    "isCaseAtRisk": false,
                },
                {
                    "count": 12000,
                    "daysRemaining": 6,
                    "isCaseAtRisk": false,
                },            
                {
                    "count": 9746,
                    "daysRemaining": 8,
                    "isCaseAtRisk": false,
                },
                {
                    "count": 8991,
                    "daysRemaining": 9,
                    "isCaseAtRisk": false,
                },

                {
                    "count": 12012,
                    "daysRemaining": 11,
                    "isCaseAtRisk": false,
                },
                {
                    "count": 9746,
                    "daysRemaining": 12,
                    "isCaseAtRisk": false,
                },
        
               
                {
                    "count": 11345,
                    "daysRemaining": 14,
                    "isCaseAtRisk": false,
                },
                {
                    "count": 12012,
                    "daysRemaining": 15,
                    "isCaseAtRisk": false,
                },
                {
                    "count": 7000,
                    "daysRemaining": 16,
                    "isCaseAtRisk": false,
                },
            ],
            "shelfLife": '16 days',
            "__typename": 'products_product',
        },
        {
            "averageShelfLife": 11.06,
            "caseValue": 44.91375,
            "casesByShelfLifeinDaysPerCte": [
                {
                    "count": 0,
                    "daysRemaining": 0,
                    "isCaseAtRisk": false,
                },
                {
                    "count":5200,
                    "daysRemaining": 1,
                    "isCaseAtRisk": true,
                },
                {
                    "count": 8700,
                    "daysRemaining": 2,
                    "isCaseAtRisk": true,
                },
                {
                    "count": 10233,
                    "daysRemaining": 3,
                    "isCaseAtRisk": true,
                },
               
                {
                    "count": 11329,
                    "daysRemaining": 5,
                    "isCaseAtRisk": false,
                },
                {
                    "count": 7833,
                    "daysRemaining": 6,
                    "isCaseAtRisk": false,
                },
               
            
                {
                    "count": 8211,
                    "daysRemaining": 7,
                    "isCaseAtRisk": false,
                },

                {
                    "count": 6988,
                    "daysRemaining": 11,
                    "isCaseAtRisk": false,
                },
                {
                    "count": 10880,
                    "daysRemaining": 12,
                    "isCaseAtRisk": false,
                },
        
               
                {
                    "count": 7899,
                    "daysRemaining": 14,
                    "isCaseAtRisk": false,
                },
                {
                    "count": 8900,
                    "daysRemaining": 15,
                    "isCaseAtRisk": false,
                },
               
            ],
            "shelfLife": '16 days',
            "__typename": 'products_product',
        },
        {
            "averageShelfLife": 11.06,
            "caseValue": 44.91375,
            "casesByShelfLifeinDaysPerCte": [
                {
                    "count": 0,
                    "daysRemaining": 0,
                    "isCaseAtRisk": false,
                },
                {
                    "count": 0,
                    "daysRemaining": 1,
                    "isCaseAtRisk": true,
                },
                {
                    "count": 10234,
                    "daysRemaining": 2,
                    "isCaseAtRisk": true,
                },
                {
                    "count": 7800,
                    "daysRemaining": 3,
                    "isCaseAtRisk": true,
                },
               
                {
                    "count": 8500,
                    "daysRemaining": 5,
                    "isCaseAtRisk": false,
                },
                
                {
                    "count": 7833,
                    "daysRemaining": 6,
                    "isCaseAtRisk": false,
                },
               
            
                {
                    "count": 9888,
                    "daysRemaining": 7,
                    "isCaseAtRisk": false,
                },

                {
                    "count": 6988,
                    "daysRemaining": 11,
                    "isCaseAtRisk": false,
                },
                {
                    "count": 7666,
                    "daysRemaining": 12,
                    "isCaseAtRisk": false,
                },   
                {
                    "count": 10099,
                    "daysRemaining": 14,
                    "isCaseAtRisk": false,
                },
                {
                    "count": 7344,
                    "daysRemaining": 15,
                    "isCaseAtRisk": false,
                },
               
            ],
            "shelfLife": '16 days',
            "__typename": 'products_product',
        },
        {
            "averageShelfLife": 11.06,
            "caseValue": 44.91375,
            "casesByShelfLifeinDaysPerCte": [
                {
                    "count": 0,
                    "daysRemaining": 0,
                    "isCaseAtRisk": false,
                },
                {
                    "count":9788,
                    "daysRemaining": 1,
                    "isCaseAtRisk": true,
                },
                {
                    "count": 7600,
                    "daysRemaining": 2,
                    "isCaseAtRisk": true,
                },
                {
                    "count": 8999,
                    "daysRemaining": 3,
                    "isCaseAtRisk": true,
                },
               
                {
                    "count": 11329,
                    "daysRemaining": 4,
                    "isCaseAtRisk": false,
                },
                
                {
                    "count": 6999,
                    "daysRemaining": 7,
                    "isCaseAtRisk": false,
                },
                {
                    "count": 7800,
                    "daysRemaining": 8,
                    "isCaseAtRisk": false,
                },
                {
                    "count": 8456,
                    "daysRemaining": 9,
                    "isCaseAtRisk": false,
                },
                {
                    "count": 9876,
                    "daysRemaining": 10,
                    "isCaseAtRisk": false,
                },

               
                {
                    "count": 7899,
                    "daysRemaining": 14,
                    "isCaseAtRisk": false,
                },
                {
                    "count": 8900,
                    "daysRemaining": 15,
                    "isCaseAtRisk": false,
                },
               
            ],
            "shelfLife": '16 days',
            "__typename": 'products_product',
        },
        {
            "averageShelfLife": 11.06,
            "caseValue": 44.91375,
            "casesByShelfLifeinDaysPerCte": [
                {
                    "count": 0,
                    "daysRemaining": 0,
                    "isCaseAtRisk": false,
                },
                {
                    "count": 6755,
                    "daysRemaining": 1,
                    "isCaseAtRisk": true,
                },
                {
                    "count": 8766,
                    "daysRemaining": 2,
                    "isCaseAtRisk": true,
                },
               
                {
                    "count": 8977,
                    "daysRemaining": 5,
                    "isCaseAtRisk": false,
                },
                {
                    "count": 10233,
                    "daysRemaining": 6,
                    "isCaseAtRisk": false,
                },
               
            
                {
                    "count": 8211,
                    "daysRemaining": 7,
                    "isCaseAtRisk": false,
                },
                {
                    "count": 9845,
                    "daysRemaining": 8,
                    "isCaseAtRisk": false,
                },

                {
                    "count": 13456,
                    "daysRemaining": 11,
                    "isCaseAtRisk": false,
                },
                {
                    "count": 9865,
                    "daysRemaining": 12,
                    "isCaseAtRisk": false,
                },
        
               
                {
                    "count": 10087,
                    "daysRemaining": 14,
                    "isCaseAtRisk": false,
                },
                {
                    "count": 6733,
                    "daysRemaining": 15,
                    "isCaseAtRisk": false,
                },
                {
                    "count": 8900,
                    "daysRemaining": 16,
                    "isCaseAtRisk": false,
                },
               
            ],
            "shelfLife": '16 days',
            "__typename": 'products_product',
        },
        {
            "averageShelfLife": 11.06,
            "caseValue": 44.91375,
            "casesByShelfLifeinDaysPerCte": [
                {
                    "count": 0,
                    "daysRemaining": 0,
                    "isCaseAtRisk": false,
                },
                {
                    "count":6450,
                    "daysRemaining": 1,
                    "isCaseAtRisk": true,
                },
                {
                    "count": 9876,
                    "daysRemaining": 3,
                    "isCaseAtRisk": true,
                },
               
                {
                    "count": 8766,
                    "daysRemaining": 4,
                    "isCaseAtRisk": false,
                },
                
                {
                    "count": 7833,
                    "daysRemaining": 6,
                    "isCaseAtRisk": false,
                },
               
                {
                    "count": 9566,
                    "daysRemaining": 7,
                    "isCaseAtRisk": false,
                },
                {
                    "count": 10236,
                    "daysRemaining": 8,
                    "isCaseAtRisk": false,
                },
                {
                    "count": 7655,
                    "daysRemaining": 10,
                    "isCaseAtRisk": false,
                },
                {
                    "count": 9655,
                    "daysRemaining": 12,
                    "isCaseAtRisk": false,
                },
        
               
                {
                    "count": 7899,
                    "daysRemaining": 14,
                    "isCaseAtRisk": false,
                },
                {
                    "count": 8900,
                    "daysRemaining": 15,
                    "isCaseAtRisk": false,
                },
                {
                    "count": 12344,
                    "daysRemaining": 16,
                    "isCaseAtRisk": false,
                },
               
            ],
            "shelfLife": '16 days',
            "__typename": 'products_product',
        },
    ], 
}

// For table in dashboard 
export const atRiskDataModel = {
    ctes: [
        {
            currentCases_aggregate: {
                aggregate: {
                    count: 0,
                    __typename: 'cases_case_aggregate_fields',
                },
                __typename: 'cases_case_aggregate',
            },
            eventId: '1',
            label: 'Production Line',
            __typename: 'cte_event',
        },
        {
            currentCases_aggregate: {
                aggregate: {
                    count: 0,
                    __typename: 'cases_case_aggregate_fields',
                },
                __typename: 'cases_case_aggregate',
            },
            eventId: '1b',
            label: 'Manufacturing',
            __typename: 'cte_event',
        },
        {
            currentCases_aggregate: {
                aggregate: {
                    count: 0,
                    __typename: 'cases_case_aggregate_fields',
                },
                __typename: 'cases_case_aggregate',
            },
            eventId: '2',
            label: 'In-Transit (Distribution)',
            __typename: 'cte_event',
        },
        {
            currentCases_aggregate: {
                aggregate: {
                    count: 94,
                    __typename: 'cases_case_aggregate_fields',
                },
                __typename: 'cases_case_aggregate',
            },
            eventId: '3',
            label: 'Distribution',
            __typename: 'cte_event',
        },
        {
            currentCases_aggregate: {
                aggregate: {
                    count: 226,
                    __typename: 'cases_case_aggregate_fields',
                },
                __typename: 'cases_case_aggregate',
            },
            eventId: '4',
            label: 'In Transit (Restaurant)',
            __typename: 'cte_event',
        },
        {
            currentCases_aggregate: {
                aggregate: {
                    count: 346,
                    __typename: 'cases_case_aggregate_fields',
                },
                __typename: 'cases_case_aggregate',
            },
            eventId: '5',
            label: 'Restaurant',
            __typename: 'cte_event',
        },
        {
            currentCases_aggregate: {
                aggregate: {
                    count: 400,
                    __typename: 'cases_case_aggregate_fields',
                },
                __typename: 'cases_case_aggregate',
            },
            eventId: '6',
            label: 'Restaurant Storage',
            __typename: 'cte_event',
        },
    ],
}

export const cteDataModel = {
    "cte_event": []
}

//END dashboard 


// For dropdown in header
export const skuDataModel = {
    products_product: [
        {
            gtin: '500539',
            label: 'Fresh Beef Patty 4:1',
            caseValue: 44.91375,
            description: null,
            __typename: 'products_product',
        },
        {
            gtin: '3474529',
            label: 'LP Bulk Ketchup ',
            caseValue: 0.0,
            description: null,
            __typename: 'products_product',
        },
        {
            gtin: '500549',
            label: 'Frozen Patties 10:1',
            caseValue: 0.0,
            description: null,
            __typename: 'products_product',
        },
    ],
}

// For expiredCases table in header
export const expiredCasesModel = {
    currentCases_aggregate: {
        aggregate: {
            count: 5,
            __typename: 'cases_case_aggregate_fields',
        },
        nodes: [
            {
                created_at: '2022-04-05T17:05:51.276-05:00',
                currentCteId: '3',
                current_place_id: '1cbbc06d-2b9b-468f-92de-1a4bcc4d6158',
                pingsAtLocation: 1,
                serialNumber: '4176365',
                site: 'MB-Orlando',
                updated_at: '2022-04-23T10:33:58.515-05:00',
                __typename: 'cases_case',
            },
            {
                created_at: '2022-04-05T17:05:51.276-05:00',
                currentCteId: '5',
                current_place_id: '1cbbc06d-2b9b-468f-92de-1a4bcc4d6158',
                pingsAtLocation: 1,
                serialNumber: '4208905',
                site: '5323',
                updated_at: '2022-04-24T10:33:58.515-05:00',
                __typename: 'cases_case',
            },
            {
                created_at: '2022-04-05T17:05:51.276-05:00',
                currentCteId: '3',
                current_place_id: '1cbbc06d-2b9b-468f-92de-1a4bcc4d6158',
                pingsAtLocation: 1,
                serialNumber: '2893',
                site: '4365843',
                updated_at: '2022-04-17T10:33:58.515-05:00',
                __typename: 'cases_case',
            },
            {
                created_at: '2022-04-05T17:05:51.276-05:00',
                currentCteId: '6',
                current_place_id: '1cbbc06d-2b9b-468f-92de-1a4bcc4d6158',
                pingsAtLocation: 1,
                serialNumber: '36489',
                site: '4756435',
                updated_at: '2022-04-27T10:33:58.515-05:00',
                __typename: 'cases_case',
            },
            {
                created_at: '2022-04-05T17:05:51.276-05:00',
                currentCteId: '6',
                current_place_id: '1cbbc06d-2b9b-468f-92de-1a4bcc4d6158',
                pingsAtLocation: 1,
                serialNumber: '36501',
                site: '456355',
                updated_at: '2022-04-19T10:33:58.515-05:00',
                __typename: 'cases_case',
            },
            {
                created_at: '2022-04-05T17:05:51.276-05:00',
                currentCteId: '3',
                current_place_id: '1cbbc06d-2b9b-468f-92de-1a4bcc4d6158',
                pingsAtLocation: 1,
                serialNumber: '4205',
                site: '4465842',
                updated_at: '2022-04-15T10:33:58.515-05:00',
                __typename: 'cases_case',
            },
            {
                created_at: '2022-04-05T17:05:51.276-05:00',
                currentCteId: '6',
                current_place_id: '1cbbc06d-2b9b-468f-92de-1a4bcc4d6158',
                pingsAtLocation: 1,
                serialNumber: '5607',
                site: '4665346',
                updated_at: '2022-04-21T10:33:58.515-05:00',
                __typename: 'cases_case',
            },
        ],
        __typename: 'cases_case_aggregate',
    },
}

// export const siteDataModel = {
//     locations_site: [
//         {
//             displayName: '10751',
//             site_code: '0195500237299',
//             __typename: 'locations_site',
//         },
//         {
//             displayName: 'MB - Orlando',
//             site_code: '0888632000038',
//             __typename: 'locations_site',
//         },
//         {
//             displayName: '36489',
//             site_code: '0195500783680',
//             __typename: 'locations_site',
//         },
//     ],
// }

export const casesModel = {
    cte_event: [
        {
            label: 'In-Transit (Distribution)',
            eventId: '2',
            pastCases_aggregate: {
                aggregate: {
                    count: 5607,
                    __typename: 'cases_caseHistory_aggregate_fields',
                },
                __typename: 'cases_caseHistory_aggregate',
            },
            __typename: 'cte_event',
        },
        {
            label: 'Distribution',
            eventId: '3',
            pastCases_aggregate: {
                aggregate: {
                    count: 25645,
                    __typename: 'cases_caseHistory_aggregate_fields',
                },
                __typename: 'cases_caseHistory_aggregate',
            },
            __typename: 'cte_event',
        },
        {
            label: 'In Transit (Restaurant)',
            eventId: '4',
            pastCases_aggregate: {
                aggregate: {
                    count: 23491,
                    __typename: 'cases_caseHistory_aggregate_fields',
                },
                __typename: 'cases_caseHistory_aggregate',
            },
            __typename: 'cte_event',
        },
        {
            label: 'Restaurant',
            eventId: '5',
            pastCases_aggregate: {
                aggregate: {
                    count: 20000,
                    __typename: 'cases_caseHistory_aggregate_fields',
                },
                __typename: 'cases_caseHistory_aggregate',
            },
            __typename: 'cte_event',
        },
        {
            label: 'Restaurant Storage',
            eventId: '6',
            pastCases_aggregate: {
                aggregate: {
                    count: 16835,
                    __typename: 'cases_caseHistory_aggregate_fields',
                },
                __typename: 'cases_caseHistory_aggregate',
            },
            __typename: 'cte_event',
        },
        {
            label: 'Production Line',
            eventId: '1',
            pastCases_aggregate: {
                aggregate: {
                    count: 0,
                    __typename: 'cases_caseHistory_aggregate_fields',
                },
                __typename: 'cases_caseHistory_aggregate',
            },
            __typename: 'cte_event',
        },
        {
            label: 'Manufacturing',
            eventId: '1b',
            pastCases_aggregate: {
                aggregate: {
                    count: 19258,
                    __typename: 'cases_caseHistory_aggregate_fields',
                },
                __typename: 'cases_caseHistory_aggregate',
            },
            __typename: 'cte_event',
        },
    ],
}

// For product table in header
export const productsDataModel = {
    products_product: [
        {
            assets: [],
            averageShelfLife: -6.444746264970984,
            caseValue: 44.91375,
            casesPerPalletCount: 98,
            description: null,
            gtin: '500539',
            id: '3ecb1b0e-834a-4c26-85d0-e7c6b3d36b91',
            label: 'Fresh Beef Patty 4:2',
            shelfLife: '16 days',
            __typename: 'products_product',
        },
        {
            assets: [],
            averageShelfLife: -9.681869707274789,
            caseValue: 44.91375,
            casesPerPalletCount: 98,
            description: null,
            gtin: '500406',
            id: '5d766808-8a2f-409e-9d78-f87415b3db2a',
            label: 'Fresh Beef Patty 4:1',
            shelfLife: '16 days',
            __typename: 'products_product',
        },
        {
            assets: [],
            averageShelfLife: null,
            caseValue: 12,
            casesPerPalletCount: 96,
            description: null,
            gtin: '3474529',
            id: '9d2a39ab-3901-40fb-be01-772283e860c8',
            label: 'LP Bulk Ketchup ',
            shelfLife: '16 days',
            __typename: 'products_product',
        },
        {
            assets: [],
            averageShelfLife: null,
            caseValue: 11,
            casesPerPalletCount: 56,
            description: null,
            gtin: '500549',
            id: '1b1ca8dd-6c87-4911-8652-b9dce05ec807',
            label: 'Frozen Patties 10:1',
            shelfLife: '90 days',
            __typename: 'products_product',
        },
    ],
}


