import React, { Suspense } from 'react'
import ErrorBoundary from './errorboundary'
import Spinner from 'components/common/spinner'

const RecordsPage = React.lazy(() => import('./records'))

const RecordsViewContainer = (props) => (
    <div>
        <Suspense fallback={<Spinner />}>
            <ErrorBoundary>
                <RecordsPage {...props} />
            </ErrorBoundary>
        </Suspense>
    </div>
)

export default RecordsViewContainer
